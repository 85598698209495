import React from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, CheckBox, Divider, List, Modal, Spinner, Text } from '@ui-kitten/components';
import { TouchableOpacity, Image, ImageBackground, Dimensions } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import DefaultLayout from '../../components/default-layout';
import { ReduxState } from '../../reduxers/reducers';
import { IScreenProps } from '../../interfaces/screen';
import Actions from '../../reduxers/actions';
import IProductState from '../../interfaces/states/product';
import Column from '../../components/grid/column';
import Row from '../../components/grid/row';
import CommonStyles from '../../styles';
import Styles from './index.style';
import { IProductDestinationJSON } from '../../interfaces/models/product';
import DefaultForm, { IDefaultFormValue } from '../../components/form';
import IProductItemState from '../../interfaces/states/product-item';
import { INQUIRY_TYPE, PADDING, PHONE_LABELS, SCREEN_MAX_WIDTH } from '../../constants';
import IProductItem from '../../interfaces/models/product-item';
import { FormatNumber } from '../../utils';
import MetaIcon from '../../components/icon';
import COLORS from '../../constants/color';
import IPaymentMethodState from '../../interfaces/states/payment-method';
import IPaymentMethod, { IPaymentMethodRequest } from '../../interfaces/models/payment-method';
import * as Snackbar from '../../components/snackbar';
import { IOrderRequest } from '../../interfaces/models/order';
import STORAGE from '../../constants/storage';
import IOrderState from '../../interfaces/states/order';
import IProductDestinationState from '../../interfaces/states/product-destination';
import IUserState from '../../interfaces/states/user';
import IInquiryState from '../../interfaces/states/inquiry';
import { IInquiryProductNoteJSON } from '../../interfaces/models/inquiry';
import background from '../../assets/images/product-background.png';

const { width } = Dimensions.get('window');
const dWidth = width < SCREEN_MAX_WIDTH ? width : SCREEN_MAX_WIDTH;
const dHeight = dWidth * (144 / 360);

interface MainProps extends IScreenProps {
  isLoggedIn: boolean;
  user: IUserState;

  product: IProductState;
  getProductById: (productId: number, callback?: () => void) => void;

  productItem: IProductItemState;
  getItemsByProductId: (productId: number, productItemId?: number, callback?: () => void) => void;

  paymentMethod: IPaymentMethodState;
  getPaymentMethod: (data: IPaymentMethodRequest) => void;

  order: IOrderState;
  createOrder: (data: IOrderRequest, callback?: () => void) => void;

  productDestination: IProductDestinationState;
  getProductDestinations: (productId: number) => void;

  inquiry: IInquiryState;
  inquiryByProduct: (productId: number, customerId: string) => void;
  inquiryByProductItem: (productId: number, productItemId: number, destination: string) => void;
  inquiryByProductType: (productTypeId: number, destination: string) => void;
  inquiryReset: () => void;
}

interface MainStates {
  selectedProductItem?: IProductItem;
  selectedPaymentMethod?: IPaymentMethod;
  showCheckoutModal: boolean;
  showCheckoutLoading: boolean;
  values?: IDefaultFormValue;
  saveDestination: boolean;
}

class PaymentScreen extends React.Component<MainProps, MainStates> {
  destinationFormRef: React.RefObject<any> = React.createRef();

  constructor(props: MainProps) {
    super(props);
    this.state = {
      showCheckoutModal: false,
      showCheckoutLoading: false,
      saveDestination: false,
    };
  }

  componentDidMount = () => {
    const { route, getProductById, getItemsByProductId, getPaymentMethod, inquiryReset, getProductDestinations } = this.props;
    if (route.params?.productId) {
      getProductById(route.params.productId, () => {
        const { product } = this.props;
        const destinations: Array<IProductDestinationJSON> = product.data?.destination ? JSON.parse(product.data.destination) : [];
        if (destinations.filter((d) => d.inquiryType && INQUIRY_TYPE.InquireByProductTypeID === d.inquiryType).length === 0) {
          getItemsByProductId(route.params?.productId, undefined, () => {
            const { productItem, product: productAfterGetItems } = this.props;
            if (productAfterGetItems.data?.name.toLowerCase().includes('postpaid') && productItem.list && productItem.list.length > 0) {
              this.setState({
                selectedProductItem: productItem.list[0],
              });
            }
          });
        }
      });
      getPaymentMethod({ productId: 0, productItemId: 0 });
      AsyncStorage.getItem(STORAGE.token).then(async (token) => {
        if (token) {
          getProductDestinations(route.params?.productId);
        }
      });
      inquiryReset();
    }
  };

  onSelectItem = (item: IProductItem) => {
    const { getPaymentMethod, route } = this.props;
    this.setState(
      {
        selectedProductItem: item,
      },
      () => {
        getPaymentMethod({ productId: route.params?.productId, productItemId: item.id });
      },
    );
  };

  onSelectPaymentMethod = (item: IPaymentMethod) => {
    this.setState(
      {
        selectedPaymentMethod: item,
      },
      () => {
        const { product, inquiryByProductItem, inquiryByProduct } = this.props;
        const { selectedProductItem } = this.state;
        if (!selectedProductItem) {
          Snackbar.show('Silakan pilih nominal', Snackbar.MEDIUM, Snackbar.WARNING);
        }

        this.destinationFormRef.current.prepareSubmit((values: IDefaultFormValue) => {
          const destinations: Array<IProductDestinationJSON> = product.data?.destination ? JSON.parse(product.data.destination) : [];
          const inquiryProductItemFields = destinations.filter((x) => x.inquiryType === INQUIRY_TYPE.InquireByProductItemID);
          const inquiryProduct = destinations.filter((x) => x.inquiryType === INQUIRY_TYPE.InquireByProductID);
          if (product.data && selectedProductItem && values) {
            const promises = [
              ...inquiryProduct.map(async (d) => {
                if (product.data && selectedProductItem && values && d.label) {
                  const value = values[d.label?.toLowerCase().split(' ').join('_')];
                  return inquiryByProduct(product.data.id, value.toString());
                }
                return undefined;
              }),
              ...inquiryProductItemFields.map(async (d) => {
                if (product.data && selectedProductItem && values && d.label) {
                  const value = values[d.label?.toLowerCase().split(' ').join('_')];
                  return inquiryByProductItem(product.data.id, selectedProductItem.id, value.toString());
                }
                return undefined;
              }),
            ];
            Promise.all(promises).then(() => {
              const { inquiry } = this.props;
              if (selectedProductItem && Object.keys(values).length > 0 && !inquiry.error) {
                this.setState({ showCheckoutModal: true, values: values as any });
              }
            });
          }
        });
      },
    );
  };

  getOrderRequest = () => {
    const { selectedProductItem, selectedPaymentMethod, values, saveDestination } = this.state;
    if (selectedProductItem && selectedPaymentMethod && values) {
      const orderRequest: IOrderRequest = {
        email: values.email.toString(),
        productItemId: selectedProductItem.id,
        paymentMethodId: selectedPaymentMethod.id,
        pointUsed: 0,
        voucherUsed: [],
        destinations: Object.values(values).map((x) => x.toString()),
        qty: 1,
        doesSaveDestination: saveDestination,
      };
      return orderRequest;
    }
    return undefined;
  };

  onCreateOrder = () => {
    const { createOrder } = this.props;
    const orderRequest = this.getOrderRequest();
    if (orderRequest) {
      this.setState(
        {
          showCheckoutLoading: true,
        },
        () => {
          createOrder(orderRequest, () => {
            const { order } = this.props;
            let showCheckoutModal = true;
            if (order.data) {
              showCheckoutModal = false;
            }

            this.setState({
              showCheckoutModal,
              showCheckoutLoading: false,
            });
          });
        },
      );
    }
  };

  onCreateOrderAfterRegister = () => {
    const { navigation } = this.props;
    const orderRequest = this.getOrderRequest();
    if (orderRequest) {
      this.setState(
        {
          showCheckoutModal: false,
        },
        async () => {
          await AsyncStorage.setItem(STORAGE.order, JSON.stringify(orderRequest));
          navigation.navigate('auth');
        },
      );
    }
  };

  getFieldOptions = (label: string) => {
    const { user, productDestination } = this.props;
    const options: Array<string | number> = [];
    if (label.toLowerCase().includes('email')) {
      if (user.data?.email) options.push(user.data.email);
    }

    if (PHONE_LABELS.includes(label.toLowerCase())) {
      if (user.data?.phoneNumber) options.push(user.data.phoneNumber);
    }

    const filteredProductDestination = productDestination.list?.filter((pd) => pd.isActive);

    (filteredProductDestination || []).forEach((pd) => {
      const destinations: Array<IProductDestinationJSON> = pd.product?.destination ? JSON.parse(pd.product.destination) : [];
      const dIndex = destinations.findIndex((d) => d.label?.toLowerCase() === label.toLowerCase());
      const dValues = pd.destination.split(',');
      if (dIndex >= 0 && options.indexOf(dValues[dIndex].trim()) < 0) options.push(dValues[dIndex].trim());
    });

    return options;
  };

  makeInquiry = async (d: IProductDestinationJSON, temp: string) => {
    const { inquiryByProduct, inquiryByProductType, product } = this.props;
    if (d.inquiryType === INQUIRY_TYPE.InquireByProductID && product.data) {
      await inquiryByProduct(product.data?.id, temp);
      const { inquiry, getPaymentMethod, route } = this.props;
      if (inquiry.data?.amount && inquiry.data?.amount > 0 && product.data.name.toLowerCase().includes('postpaid')) {
        getPaymentMethod({ productId: route.params?.productId, productItemId: this.state.selectedProductItem?.id, customerID: temp });
      }
    } else if (d.inquiryType === INQUIRY_TYPE.InquireByProductTypeID && product.data) {
      inquiryByProductType(product.data.type.id, temp);
    }
  };

  render = () => {
    const { product, productItem, paymentMethod, isLoggedIn, inquiry, order } = this.props;
    const { selectedProductItem, showCheckoutModal, selectedPaymentMethod, values, saveDestination, showCheckoutLoading } = this.state;
    const destinations: Array<IProductDestinationJSON> = product.data?.destination ? JSON.parse(product.data.destination) : [];
    const inquiryDestinations = destinations.filter((d) => d.inquiryType);
    const inquiryNote: IInquiryProductNoteJSON = inquiry.data && inquiry.data.note ? JSON.parse(inquiry.data.note) : {};
    const inquiryDetails = [
      { label: 'Nama Lengkap', value: inquiryNote.name },
      { label: 'No Meter', value: inquiryNote.meterNo || inquiry.data?.customerId },
      { label: 'Tarif/Daya', value: inquiryNote.segmentPower || inquiry.data?.productName },
      { label: 'Jumlah Periode', value: inquiry.data?.details?.length },
      { label: 'Periode', value: inquiry.data?.details?.map((x) => x.periode).join(', ') },
    ];
    const denda = inquiry.data?.details?.map((x) => x.penaltyFee).reduce((a, b) => a + b);
    if (denda && denda > 0) {
      inquiryDetails.push({ label: 'Denda', value: `Rp ${FormatNumber(denda)}` });
    }
    const admin = inquiry.data?.details?.map((x) => x.adminFee).reduce((a, b) => a + b);
    if (admin && admin > 0) {
      inquiryDetails.push({ label: 'Biaya Admin', value: `Rp ${FormatNumber(admin)}` });
    }
    const total = inquiry.data?.amount;
    if (total && total > 0) {
      inquiryDetails.push({ label: 'Total', value: `Rp ${FormatNumber(total)}` });
    }
    return (
      <DefaultLayout backButton {...this.props}>
        <ImageBackground
          resizeMode="contain"
          source={product.data?.imageBackground ? { uri: product.data.imageBackground } : background}
          style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PH1, CommonStyles.Background.divider, { height: dHeight, width: dWidth }]}
        >
          <Column align="center" justify="center">
            <Avatar source={{ uri: product.data?.image }} size="giant" shape="square" style={[CommonStyles.BorderRadius.BR3, CommonStyles.Background.blackTwo]} />
            <Text style={[CommonStyles.Typography.TBold, CommonStyles.Space.MT1]}>{product.data?.name}</Text>
          </Column>
        </ImageBackground>
        <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PT1]}>
          <Column>
            <DefaultForm
              style={[CommonStyles.Space.MB1]}
              fields={destinations.map((d) => ({
                label: d.label || '',
                helpContent: d.helpImage ? (
                  <Row>
                    <Image source={{ uri: d.helpImage }} style={{ width: 300, height: 200 }} resizeMode="contain" />
                  </Row>
                ) : undefined,
                name: (d.label || '').toLowerCase().split(' ').join('_'),
                type: d.type || 'text',
                required: d.isRequired,
                caption: d.caption,
                options: this.getFieldOptions(d.label || ''),
                autoselect: !!(d.label && ['email'].includes(d.label.toLowerCase())),
                disableOnBlur: true,
                detailContent:
                  d.inquiryType === INQUIRY_TYPE.InquireByProductID ? (
                    <Row style={[Styles.detailBox, CommonStyles.Space.P1, CommonStyles.Space.MT1, CommonStyles.Layout.fullWidth]}>
                      <Column align="center">
                        <Row>
                          <Text style={[CommonStyles.Typography.TS14, CommonStyles.Typography.TSemiBold]}>Informasi Pelanggan</Text>
                        </Row>
                        <Divider style={[CommonStyles.Space.MV1, CommonStyles.Layout.fullWidth]} />
                        {inquiry.error ? (
                          <Row>
                            <Text style={[CommonStyles.Typography.TS14, CommonStyles.Typography.TRegular]} status="danger">
                              {inquiry.error}
                            </Text>
                          </Row>
                        ) : inquiry.data ? (
                          <>
                            {inquiryDetails
                              .filter((x) => x.value)
                              .map((x) => (
                                <Row key={x.label} style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB1]}>
                                  <Column>
                                    <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS16]}>{x.label}</Text>
                                  </Column>
                                  <Column align="end">
                                    <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>{x.value}</Text>
                                  </Column>
                                </Row>
                              ))}
                          </>
                        ) : (
                          <Row>
                            <Text style={[CommonStyles.Typography.TS14, CommonStyles.Typography.TRegular]}>{`Mohon mengisi ${d.label}`}</Text>
                          </Row>
                        )}
                      </Column>
                    </Row>
                  ) : d.label && ['email'].includes(d.label.toLowerCase()) ? (
                    <Row>
                      <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Space.MT1, CommonStyles.Typography.Tgrey]}>
                        Tagihan pembayaran dan bukti transaksi akan kami kirimkan ke email di atas
                      </Text>
                    </Row>
                  ) : undefined,
                onSubmit: (tempValue: string) => this.makeInquiry(d, tempValue),
              }))}
              ref={this.destinationFormRef}
            />
            {product.data?.name.toLowerCase().includes('postpaid') ? (
              <></>
            ) : (
              <>
                <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, { height: PADDING / 2 }]} />
                <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.P1]}>
                  <Column>
                    <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Nominal</Text>
                  </Column>
                </Row>
                <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PH0_5, CommonStyles.Space.MB0_5]}>
                  <List
                    style={[CommonStyles.Background.transparent, CommonStyles.Layout.fullWidth]}
                    numColumns={3}
                    data={productItem.list?.filter((pi) => pi.product.id === product.data?.id)}
                    renderItem={({ item }: { item: IProductItem }) => (
                      <TouchableOpacity style={[{ flex: 1 / 3 }]} onPress={() => this.onSelectItem(item)}>
                        <Column style={[Styles.productItem, selectedProductItem && selectedProductItem.id === item.id ? Styles.productItemActive : {}]} align="center">
                          {selectedProductItem && selectedProductItem.id === item.id ? <MetaIcon name="selection" color={COLORS.price} size={23} style={[Styles.productItemSelection]} /> : <></>}
                          <Text style={[CommonStyles.Typography.TS14, CommonStyles.Typography.TCenter, CommonStyles.Typography.TSemiBold, CommonStyles.Space.MB1]} numberOfLines={2}>
                            {item.name}
                          </Text>
                          <Text
                            style={[
                              CommonStyles.Typography.TS14,
                              CommonStyles.Typography.TCenter,
                              CommonStyles.Typography.TRegular,
                              selectedProductItem && selectedProductItem.id === item.id ? CommonStyles.Typography.Tprice : {},
                            ]}
                          >
                            {`Rp ${FormatNumber(item.price)}`}
                          </Text>
                        </Column>
                      </TouchableOpacity>
                    )}
                    ListEmptyComponent={(
                      <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PH0_5, CommonStyles.Space.PV1]}>
                        <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.T14, CommonStyles.Typography.Tprice]}>
                          {inquiryDestinations.length > 0
                            ? `Mohon mengisi ${inquiryDestinations.map((d) => d.label).join(', ')} terlebih dahulu`
                            : `Mohon maaf, saat ini belum tersedia item untuk produk ${product.data?.name}.`}
                        </Text>
                      </Row>
                    )}
                    refreshing={productItem.requesting}
                  />
                </Row>
              </>
            )}
            <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, { height: PADDING / 2 }]} />
            <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.P1]}>
              <Column>
                <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Metode Pembayaran</Text>
              </Column>
            </Row>
            <List
              style={[CommonStyles.Background.transparent, CommonStyles.Layout.fullWidth]}
              data={paymentMethod.list}
              renderItem={({ item }: { item: IPaymentMethod }) => (
                <TouchableOpacity onPress={() => this.onSelectPaymentMethod(item)}>
                  <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB1, CommonStyles.Space.PH1]}>
                    <Column style={[CommonStyles.Space.P1, CommonStyles.Background.divider, CommonStyles.BorderRadius.BR3]}>
                      <Row style={[CommonStyles.Layout.fullWidth]} align="center">
                        <Column>
                          <Row align="center">
                            <Avatar source={{ uri: item.image }} size="giant" shape="square" style={[CommonStyles.Space.MR1, CommonStyles.BorderRadius.BR3]} />
                            <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS16]}>{item.methodName}</Text>
                          </Row>
                        </Column>
                        {selectedProductItem ? (
                          <Column align="end">
                            <Row>
                              <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS14]}>Total Harga</Text>
                            </Row>
                            <Row>
                              <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS14, CommonStyles.Typography.Tprice]}>{`Rp ${FormatNumber(item.totalPayment)}`}</Text>
                            </Row>
                          </Column>
                        ) : (
                          <></>
                        )}
                        <MetaIcon name="arrow" size={11} color={COLORS.theme} style={[CommonStyles.Space.ML1]} />
                      </Row>
                    </Column>
                  </Row>
                </TouchableOpacity>
              )}
            />
          </Column>
        </Row>
        <Modal visible={showCheckoutLoading} backdropStyle={[Styles.checkoutModalBackdrop]}>
          <Row align="center">
            <Column>
              <Spinner />
            </Column>
          </Row>
        </Modal>
        <Modal visible={showCheckoutModal} backdropStyle={[Styles.checkoutModalBackdrop]} style={[Styles.checkoutModal]}>
          <Row align="center">
            <Column>
              <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Konfirmasi Pesanan</Text>
            </Column>
            <TouchableOpacity onPress={() => this.setState({ showCheckoutModal: false })}>
              <MetaIcon name="close-overlay" size={20} />
            </TouchableOpacity>
          </Row>
          <Divider style={[CommonStyles.Space.MV1]} />
          <Row align="center" style={[CommonStyles.Space.MB1]}>
            <Column>
              <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Produk</Text>
            </Column>
            <Column align="end">
              <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS16, CommonStyles.Typography.TRight]}>{`${product.data?.name} - ${selectedProductItem?.name}`}</Text>
            </Column>
          </Row>
          <Row align="center" style={[CommonStyles.Space.MB1]}>
            <Column>
              <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Harga</Text>
            </Column>
            <Column align="end">
              <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS16]}>{`Rp ${FormatNumber(selectedPaymentMethod?.totalPayment || 0)}`}</Text>
            </Column>
          </Row>
          {destinations.map((d) => (
            <Row key={d.label} align="center" style={[CommonStyles.Space.MB1]}>
              <Column>
                <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>{d.label}</Text>
              </Column>
              <Column align="end">
                <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS16]}>{values && d.label && (values[d.label.toLowerCase().split(' ').join('_')] || '-')}</Text>
              </Column>
            </Row>
          ))}
          <Row align="center" style={[CommonStyles.Space.MB1]}>
            <Column>
              <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Metode Pembayaran</Text>
            </Column>
            <Column align="end">
              <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS16]}>{selectedPaymentMethod?.methodName}</Text>
            </Column>
          </Row>
          {isLoggedIn ? (
            <>
              <Row style={[CommonStyles.Space.MV1]}>
                <CheckBox status="info" checked={saveDestination} onChange={(nextChecked) => this.setState({ saveDestination: nextChecked })}>
                  {() => (
                    <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS16, CommonStyles.Space.ML1]}>
                      {`Simpan ${destinations.map((d) => d.label).join(', ')} di profil saya`}
                    </Text>
                  )}
                </CheckBox>
              </Row>
              <Row style={[CommonStyles.Space.MV1]}>
                <Button disabled={order.requesting} style={[CommonStyles.Layout.fullWidth]} status="info" onPress={this.onCreateOrder}>
                  {() => <Text style={[CommonStyles.Typography.TS, CommonStyles.Typography.TS16, CommonStyles.Space.ML1, CommonStyles.Typography.Twhite]}>Konfirmasi Pemesanan</Text>}
                </Button>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Button style={[CommonStyles.Layout.fullWidth]} status="info" onPress={this.onCreateOrderAfterRegister}>
                  {() => <Text style={[CommonStyles.Typography.TS, CommonStyles.Typography.TS16, CommonStyles.Space.ML1, CommonStyles.Typography.Twhite]}>Register dan dapatkan DISKON 20%</Text>}
                </Button>
              </Row>
              <Row style={[CommonStyles.Space.MT1]}>
                <Button disabled={order.requesting} style={[CommonStyles.Layout.fullWidth]} status="info" appearance="ghost" onPress={this.onCreateOrder}>
                  {() => <Text style={[CommonStyles.Typography.TS, CommonStyles.Typography.TS16, CommonStyles.Space.ML1, CommonStyles.Typography.TinfoBlue]}>Lanjut transaksi tanpa akun</Text>}
                </Button>
              </Row>
            </>
          )}
        </Modal>
      </DefaultLayout>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  isLoggedIn: !!state.user.data,
  inquiry: state.inquiry,
  order: state.order,
  product: state.product,
  productDestination: state.productDestination,
  productItem: state.productItem,
  paymentMethod: state.paymentMethod,
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  getProductById: (productId: number, callback?: () => void) => dispatch(Actions.product.getById(productId, callback)),
  getItemsByProductId: (productId: number, productItemId?: number, callback?: () => void) => dispatch(Actions.productItem.getList(productId, productItemId, callback)),
  getPaymentMethod: (data: IPaymentMethodRequest) => dispatch(Actions.paymentMethod.getList(data)),
  createOrder: (data: IOrderRequest, callback?: () => void) => dispatch(Actions.order.create(data, callback)),
  getProductDestinations: (productId: number) => dispatch(Actions.productDestination.getList(productId)),
  inquiryByProduct: (productId: number, customerId: string) => dispatch(Actions.inquiry.getByProduct(productId, customerId)),
  inquiryByProductItem: (productId: number, productItemId: number, destination: string) => dispatch(Actions.inquiry.getByProductItem(productId, productItemId, destination)),
  inquiryByProductType: (productTypeId: number, destination: string) => dispatch(Actions.inquiry.getByProductType(productTypeId, destination)),
  inquiryReset: () => dispatch(Actions.inquiry.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentScreen);
