const INQUIRY_BY_PRODUCT_REQUEST = 'INQUIRY_BY_PRODUCT_REQUEST';
const INQUIRY_BY_PRODUCT_SUCCESS = 'INQUIRY_BY_PRODUCT_SUCCESS';
const INQUIRY_BY_PRODUCT_FAILURE = 'INQUIRY_BY_PRODUCT_FAILURE';

const INQUIRY_BY_PRODUCT_ITEM_REQUEST = 'INQUIRY_BY_PRODUCT_ITEM_REQUEST';
const INQUIRY_BY_PRODUCT_ITEM_SUCCESS = 'INQUIRY_BY_PRODUCT_ITEM_SUCCESS';
const INQUIRY_BY_PRODUCT_ITEM_FAILURE = 'INQUIRY_BY_PRODUCT_ITEM_FAILURE';

const INQUIRY_BY_PRODUCT_TYPE_REQUEST = 'INQUIRY_BY_PRODUCT_TYPE_REQUEST';
const INQUIRY_BY_PRODUCT_TYPE_SUCCESS = 'INQUIRY_BY_PRODUCT_TYPE_SUCCESS';
const INQUIRY_BY_PRODUCT_TYPE_FAILURE = 'INQUIRY_BY_PRODUCT_FAILURE';

const INQUIRY_RESET = 'INQUIRY_RESET';

export default {
  INQUIRY_BY_PRODUCT_REQUEST,
  INQUIRY_BY_PRODUCT_FAILURE,
  INQUIRY_BY_PRODUCT_SUCCESS,

  INQUIRY_BY_PRODUCT_ITEM_REQUEST,
  INQUIRY_BY_PRODUCT_ITEM_FAILURE,
  INQUIRY_BY_PRODUCT_ITEM_SUCCESS,

  INQUIRY_BY_PRODUCT_TYPE_REQUEST,
  INQUIRY_BY_PRODUCT_TYPE_FAILURE,
  INQUIRY_BY_PRODUCT_TYPE_SUCCESS,

  INQUIRY_RESET,
};
