import { StyleSheet } from 'react-native';
import { SCREEN_MAX_WIDTH } from '../../constants';
import COLORS from '../../constants/color';

const Styles = StyleSheet.create({
  pendingBox: {
    borderRadius: 3,
    shadowColor: COLORS.blackOne,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.16,
    shadowRadius: 9,
  },
  modalBackdrop: {
    maxWidth: SCREEN_MAX_WIDTH,
    marginHorizontal: 'auto',
  },
});

export default Styles;
