/* eslint-disable camelcase */
import React from 'react';
import {
  useFonts,
  SourceSansPro_700Bold,
  SourceSansPro_700Bold_Italic,
  SourceSansPro_600SemiBold,
  SourceSansPro_600SemiBold_Italic,
  SourceSansPro_400Regular,
  SourceSansPro_400Regular_Italic,
} from '@expo-google-fonts/source-sans-pro';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import * as eva from '@eva-design/eva';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { createLogger } from 'redux-logger';
import { Image, Dimensions } from 'react-native';
import ENV from './utils/environment';
import AppNavigator from './navigation/app.navigator';
import { rootReducer } from './reduxers/reducers';
import SnackbarComponent, { snackbarRef } from './components/snackbar';
import theme from './styles/ui-kitten/theme.json';
import splash from './assets/images/splash.png';
import { SCREEN_MAX_WIDTH } from './constants';

const middlewares = [];
middlewares.push(thunkMiddleware);
if (ENV.DEBUG) middlewares.push(createLogger());
const store = createStore(rootReducer, applyMiddleware(...middlewares));

const { width } = Dimensions.get('window');

const App = () => (
  <Provider store={store}>
    <IconRegistry icons={EvaIconsPack} />
    <ApplicationProvider {...eva} theme={{ ...eva.light, ...theme }}>
      <SafeAreaProvider>
        <SnackbarComponent ref={snackbarRef} />
        <AppNavigator />
      </SafeAreaProvider>
    </ApplicationProvider>
  </Provider>
);

export default (): React.ReactElement => {
  const [fontsLoaded] = useFonts({
    SourceSansPro_700Bold,
    SourceSansPro_700Bold_Italic,
    SourceSansPro_600SemiBold,
    SourceSansPro_600SemiBold_Italic,
    SourceSansPro_400Regular,
    SourceSansPro_400Regular_Italic,
  });

  if (fontsLoaded) {
    return <App />;
  }

  return <Image source={splash} style={{ flex: 1, width: width < SCREEN_MAX_WIDTH ? width : SCREEN_MAX_WIDTH, marginHorizontal: 'auto' }} />;
};
