import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import { Linking } from 'expo';
import AsyncStorage from '@react-native-community/async-storage';
import IOrder, { IOrderRequest } from '../../interfaces/models/order';
import { IBaseResponse } from '../../interfaces/responses/base';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';
import * as Navigation from '../../utils/navigation';
import STORAGE from '../../constants/storage';

const createRequest = () => action(ActionTypes.order.ORDER_CREATE_REQUEST);
const createFailure = (error: string) => action(ActionTypes.order.ORDER_CREATE_FAILURE, error);

const create = (data: IOrderRequest, callback?: () => void) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(
    dispatch,
    Api.order.create(data),
    createRequest,
    (res: IBaseResponse<IOrder>) => {
      if (res.success && res.data) {
        AsyncStorage.removeItem(STORAGE.order);
        if (res.data.redirectUrl) {
          Linking.openURL(res.data.redirectUrl);
        } else {
          Navigation.navigate('order', { receiptId: res.data.receiptID });
        }
      }
      return action(ActionTypes.order.ORDER_CREATE_SUCCESS, res.data);
    },
    createFailure,
    callback,
  );
};

const getListRequest = () => action(ActionTypes.order.ORDER_GET_LIST_REQUEST);
const getListFailure = (error: string) => action(ActionTypes.order.ORDER_GET_LIST_FAILURE, error);
const getListSuccess = (res: IBaseResponse<Array<IOrder>>) => action(ActionTypes.order.ORDER_GET_LIST_SUCCESS, res.data);

const getList = () => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.order.getList(), getListRequest, getListSuccess, getListFailure);
};

const getByIdRequest = () => action(ActionTypes.order.ORDER_GET_BY_ID_REQUEST);
const getByIdFailure = (error: string) => action(ActionTypes.order.ORDER_GET_BY_ID_FAILURE, error);
const getByIdSuccess = (res: IBaseResponse<IOrder>) => action(ActionTypes.order.ORDER_GET_BY_ID_SUCCESS, res.data);

const getByReceiptId = (receiptId: string, callback?: () => void) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.order.getByReceiptId(receiptId), getByIdRequest, getByIdSuccess, getByIdFailure, callback);
};

const cancelRequest = () => action(ActionTypes.order.ORDER_CANCEL_REQUEST);
const cancelFailure = (error: string) => action(ActionTypes.order.ORDER_CANCEL_FAILURE, error);
const cancelSuccess = (res: IBaseResponse<IOrder>) => action(ActionTypes.order.ORDER_CANCEL_SUCCESS, res.data);

const cancel = (receiptId: string, callback?: () => void) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.order.cancel(receiptId), cancelRequest, cancelSuccess, cancelFailure, callback);
};

export default {
  create,
  getList,
  getByReceiptId,
  cancel,
};
