const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
const ORDER_CREATE_FAILURE = 'ORDER_CREATE_FAILURE';

const ORDER_GET_LIST_REQUEST = 'ORDER_GET_LIST_REQUEST';
const ORDER_GET_LIST_SUCCESS = 'ORDER_GET_LIST_SUCCESS';
const ORDER_GET_LIST_FAILURE = 'ORDER_GET_LIST_FAILURE';

const ORDER_GET_BY_ID_REQUEST = 'ORDER_GET_BY_ID_REQUEST';
const ORDER_GET_BY_ID_SUCCESS = 'ORDER_GET_BY_ID_SUCCESS';
const ORDER_GET_BY_ID_FAILURE = 'ORDER_GET_BY_ID_FAILURE';

const ORDER_CANCEL_REQUEST = 'ORDER_CANCEL_REQUEST';
const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS';
const ORDER_CANCEL_FAILURE = 'ORDER_CANCEL_FAILURE';

export default {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_FAILURE,
  ORDER_CREATE_SUCCESS,

  ORDER_GET_LIST_REQUEST,
  ORDER_GET_LIST_FAILURE,
  ORDER_GET_LIST_SUCCESS,

  ORDER_GET_BY_ID_REQUEST,
  ORDER_GET_BY_ID_FAILURE,
  ORDER_GET_BY_ID_SUCCESS,

  ORDER_CANCEL_REQUEST,
  ORDER_CANCEL_FAILURE,
  ORDER_CANCEL_SUCCESS,
};
