import { StyleSheet } from 'react-native';
import colors from '../../constants/color';

const Styles = StyleSheet.create({
  icon: {
    marginRight: 16,
    backgroundColor: colors.blackFour,
    borderRadius: 5,
    borderColor: colors.blackFour,
  },
  icon2: {
    marginRight: 5,
  },
  image: {
    width: 42,
    height: 42,
    borderRadius: 5,
    marginRight: 14,
    backgroundColor: colors.blackFour,
  },
  image2: {
    width: 64,
    height: 64,
    borderRadius: 5,
    marginRight: 24,
    backgroundColor: colors.blackFour,
  },
});

export default Styles;
