import React from 'react';
import { connect } from 'react-redux';
import { TouchableOpacity } from 'react-native';
import { Text } from '@ui-kitten/components';
import DefaultLayout from '../../components/default-layout';
import { ReduxState } from '../../reduxers/reducers';
import { IScreenProps } from '../../interfaces/screen';
import Row from '../../components/grid/row';
import CommonStyles from '../../styles';
import Column from '../../components/grid/column';
import IUserState from '../../interfaces/states/user';
import Actions from '../../reduxers/actions';
import IProductDestinationState from '../../interfaces/states/product-destination';
import DefaultForm, { IDefaultFormValue } from '../../components/form';
import { IUserUpdateRequest } from '../../interfaces/models/user';
import MetaIcon from '../../components/icon';
import COLORS from '../../constants/color';

interface MainProps extends IScreenProps {
  user: IUserState;
  productDestination: IProductDestinationState;

  update: (data: IUserUpdateRequest, callback?: () => void) => Promise<any>;
}

const EditProfileScreen = (props: MainProps): React.ReactElement => {
  const { user, navigation, update } = props;

  const savePhoneNumber = (data?: IDefaultFormValue) => {
    const phoneNumber: string = data?.phoneNumber.toString() || '';
    if (data && user.data?.email) {
      const req: IUserUpdateRequest = {
        phoneNumber,
        email: user.data?.email,
        billingEmail: user.data?.email,
        fullName: user.data?.fullName,
      };
      update(req, () => navigation.navigate('profile'));
    }
  };

  return (
    <DefaultLayout
      footer
      backButton={(
        <TouchableOpacity onPress={() => navigation.navigate('profile')}>
          <Row style={[CommonStyles.Space.P1, { backgroundColor: COLORS.divider }]}>
            <MetaIcon name="back" color={COLORS.infoBlue} size={18} />
            <Text style={[CommonStyles.Typography.TinfoBlue, CommonStyles.Typography.TS14, CommonStyles.Typography.TRegular, CommonStyles.Space.ML0_5]}>Akun saya</Text>
          </Row>
        </TouchableOpacity>
      )}
      {...props}
    >
      <Row>
        <Column>
          <Row style={[CommonStyles.Layout.fullWidth]}>
            <Column style={[CommonStyles.Space.PH1, CommonStyles.Space.PT1]}>
              <Row>
                <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS16]}>Masukkan nomor telepon anda</Text>
              </Row>
              <Row style={[CommonStyles.Space.MT1]}>
                <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS14]}>
                  Nomor telepon diperlukan untuk pengisian produk e-wallet, pulsa, dan paket data.
                </Text>
              </Row>
            </Column>
          </Row>
          <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB3]}>
            <Column style={[CommonStyles.Space.PT1]}>
              <DefaultForm
                fields={[
                  {
                    label: 'Nomor Telepon',
                    name: 'phoneNumber',
                    type: 'text',
                    required: true,
                    value: user.data?.phoneNumber?.toString() || '',
                  },
                ]}
                button={{
                  text: <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS14, CommonStyles.Typography.Twhite]}>Simpan Nomor Telepon</Text>,
                  action: savePhoneNumber,
                  block: true,
                }}
              />
            </Column>
          </Row>
        </Column>
      </Row>
    </DefaultLayout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  update: (data: IUserUpdateRequest, callback?: () => void) => dispatch(Actions.user.update(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileScreen);
