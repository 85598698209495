import { StyleSheet, PixelRatio } from 'react-native';
import COLORS from '../constants/color';

const createTypographyWeightVariant = () => {
  const variant: { [k: string]: any } = {};
  variant.TRegular = { fontFamily: 'SourceSansPro_400Regular' };
  variant.TRegularItalic = { fontFamily: 'SourceSansPro_400Regular_Italic' };
  variant.TSemiBold = { fontFamily: 'SourceSansPro_600SemiBold' };
  variant.TSemiBoldItalic = { fontFamily: 'SourceSansPro_600SemiBold_Italic' };
  variant.TBold = { fontFamily: 'SourceSansPro_700Bold' };
  variant.TBoldItalic = { fontFamily: 'SourceSansPro_700Bold_Italic' };

  return variant;
};
const createTypographyAlignmentVariant = () => {
  const variant: { [k: string]: any } = {};
  variant.TLeft = { textAlign: 'left' };
  variant.TCenter = { textAlign: 'center' };
  variant.TRight = { textAlign: 'right' };

  return variant;
};

const createTypographyColorVariant = (colors: { [k: string]: string }) => {
  const variant: { [k: string]: any } = {};
  Object.keys(colors).forEach((c) => {
    variant[`T${c}`] = { color: colors[c] };
  });

  return variant;
};

const createTypographySizeVariant = (sizes: Array<number>) => {
  const variant: { [k: string]: any } = {};
  sizes.forEach((s) => {
    variant[`TS${s}`] = {
      fontSize: s * PixelRatio.getFontScale(),
    };
  });

  return variant;
};

const createTypographyLineHeightVariant = (sizes: Array<number>) => {
  const variant: { [k: string]: any } = {};
  sizes.forEach((s) => {
    variant[`TLH${s}`] = {
      lineHeight: s * PixelRatio.getFontScale(),
    };
  });

  return variant;
};

const VarTypography = StyleSheet.create({
  ...createTypographyWeightVariant(),
  ...createTypographyColorVariant(COLORS),
  ...createTypographyAlignmentVariant(),
  ...createTypographySizeVariant([10, 14, 16, 18, 32]),
  ...createTypographyLineHeightVariant([22, 24]),
});

export default VarTypography;
