import React from 'react';
import { connect } from 'react-redux';
import { TouchableOpacity, Image, View } from 'react-native';
import { Avatar, Divider, List, Text } from '@ui-kitten/components';
import DefaultLayout from '../../components/default-layout';
import { ReduxState } from '../../reduxers/reducers';
import { IScreenProps } from '../../interfaces/screen';
import Row from '../../components/grid/row';
import CommonStyles from '../../styles';
import Column from '../../components/grid/column';
import IProduct, { IProductDestinationJSON } from '../../interfaces/models/product';
import COLORS from '../../constants/color';
import Actions from '../../reduxers/actions';
import DefaultForm, { IDefaultFormValue } from '../../components/form';
import MetaIcon from '../../components/icon';
import noProductImage from '../../assets/images/icon-no-product.png';
import styles from './index.style';
import IProductDestination, { IRequestParamsDestination } from '../../interfaces/models/product-destination';
import IProductDestinationState from '../../interfaces/states/product-destination';

interface MainProps extends IScreenProps {
  products: Array<IProduct> | undefined;
  productDestinations: IProductDestinationState;
  getAllProducts: () => Promise<any>;
  addDestination: (data: IRequestParamsDestination, callback?: () => void) => Promise<any>;
  removeDestination: (destId: string, callback?: () => void) => Promise<any>;
  getProductdestination: () => Promise<any>;
}

const AddUserDestinationScreen = (props: MainProps): React.ReactElement => {
  const { route, navigation, products, productDestinations, getAllProducts, addDestination, removeDestination, getProductdestination } = props;
  const [selectedProduct, setSelectedProduct] = React.useState<IProduct | undefined>(undefined);
  const [isSelectProductPage, setIsSelectProductPage] = React.useState(false);

  const productId = route.params && route.params.productId;

  const filteredProductsWihDestinations = products?.filter((p) => {
    p.destinationArray = productDestinations.list?.filter((d) => d?.isActive && d?.product?.id === p.id);
    return (p?.destinationArray?.length || 0) > 0;
  });

  const filteredProducts = products?.filter((x) => !filteredProductsWihDestinations?.find((p) => p.id.toString() === x.id.toString()));

  React.useEffect(() => {
    if (productDestinations.list?.length === 0) getProductdestination();
    if (products?.length === 0) {
      getAllProducts();
    } else {
      let product = filteredProductsWihDestinations?.find((x) => x.id.toString() === productId);
      if (!product) product = products?.find((p) => p.id.toString() === productId);
      setSelectedProduct(product);
    }
  }, [JSON.stringify(products), JSON.stringify(route.params), JSON.stringify(productDestinations.list)]);

  const destinations: Array<IProductDestinationJSON> | undefined = selectedProduct ? JSON.parse(selectedProduct?.destination || '') : undefined;

  const goToSelectProduct = () => {
    setIsSelectProductPage(true);
  };

  const selectProduct = (product: IProduct) => {
    setIsSelectProductPage(false);
    navigation.navigate('add-user-destination', { productId: product.id.toString() });
  };

  const saveID = (values?: IDefaultFormValue) => {
    const destination = values && Object.values(values).join(', ');
    const data = {
      product: {
        id: selectedProduct?.id || 0,
      },
      destination: destination || '',
      isActive: true,
    };
    addDestination(data, () => navigation.navigate('profile'));
  };

  const deleteDestination = (destinationID: number) => {
    removeDestination(destinationID.toString());
  };

  const renderAddID = () => (
    <Column>
      {(selectedProduct?.destinationArray || []).length === 0 ? (
        <Row style={[CommonStyles.Layout.fullWidth]}>
          <Column style={[CommonStyles.Space.PH1, CommonStyles.Space.PT1]}>
            <Row>
              <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS16]}>Tambah ID di Profil</Text>
            </Row>
            <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, CommonStyles.Space.MV1]} />
            <TouchableOpacity style={CommonStyles.Layout.fullWidth} onPress={goToSelectProduct}>
              <Row style={[CommonStyles.Layout.fullWidth]} align="center">
                <Avatar style={styles.icon} shape="square" source={selectedProduct ? { uri: selectedProduct.image } : noProductImage} />
                <Column>
                  <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS14, CommonStyles.Typography.TLH24]}>Produk</Text>
                  {selectedProduct ? (
                    <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS14, CommonStyles.Typography.TLH24]}>{selectedProduct.name}</Text>
                  ) : (
                    <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS14, CommonStyles.Typography.TLH24]}>Silakan pilih produk</Text>
                  )}
                </Column>
                <MetaIcon name="arrow" color={COLORS.blackOne} size={16} style={[CommonStyles.Space.ML1]} />
              </Row>
            </TouchableOpacity>
            <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, CommonStyles.Space.MV1]} />
          </Column>
        </Row>
      ) : (
        <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MV1]}>
          <Column style={[CommonStyles.Space.PH1, CommonStyles.Space.PT1]}>
            <View style={CommonStyles.Layout.fullWidth}>
              <Row style={[CommonStyles.Layout.fullWidth]} align="center">
                <Image source={selectedProduct ? { uri: selectedProduct.image } : noProductImage} style={styles.image2} />
                <Column>
                  <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS16, CommonStyles.Typography.TLH24]}>{selectedProduct?.name || ''}</Text>
                  <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackTwo, CommonStyles.Typography.TS14, CommonStyles.Typography.TLH24]}>
                    <MetaIcon name="save" color={COLORS.blackOne} size={14} style={styles.icon2} />
                    {`${selectedProduct?.destinationArray?.length}/5 ID tersimpan`}
                  </Text>
                </Column>
              </Row>
            </View>
          </Column>
        </Row>
      )}
      {(selectedProduct?.destinationArray || []).length > 0 ? <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, CommonStyles.Space.MV1]} /> : null}
      <List
        style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.transparent]}
        data={selectedProduct?.destinationArray}
        renderItem={({ item, index }: { item: IProductDestination; index: number }) => (
          <View key={item.id}>
            <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PH1]}>
              <Column>
                <Row key={index} style={[CommonStyles.Layout.fullWidth]} align="center">
                  <Column>
                    <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS14, CommonStyles.Typography.TLH24]}>{item.destination}</Text>
                  </Column>
                  <TouchableOpacity onPress={() => deleteDestination(item.id)}>
                    <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackTwo, CommonStyles.Typography.TS14, CommonStyles.Typography.TLH24]}>Hapus</Text>
                  </TouchableOpacity>
                </Row>
              </Column>
            </Row>
            {index < (selectedProduct?.destinationArray || []).length && <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, CommonStyles.Space.MV1]} />}
          </View>
        )}
      />
      {(selectedProduct?.destinationArray || []).length < 5 ? (
        <>
          <Row style={[CommonStyles.Layout.fullWidth]}>
            <Column style={[CommonStyles.Space.PH1, CommonStyles.Space.PT1]}>
              <Row>
                <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS16]}>{`Tambah ID ${selectedProduct?.name || ''}`}</Text>
              </Row>
            </Column>
          </Row>
          <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB3]}>
            <Column style={[CommonStyles.Space.PT1]}>
              {selectedProduct ? (
                <Row style={CommonStyles.Layout.fullWidth}>
                  <DefaultForm
                    fields={destinations?.map((d) => ({
                      label: d.label || '',
                      name: (d.label || '').toLowerCase().split(' ').join('_'),
                      type: d.type || 'text',
                      required: d.isRequired,
                      caption: d.caption,
                    }))}
                    button={{
                      text: <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS14, CommonStyles.Typography.Twhite]}>Simpan</Text>,
                      action: saveID,
                      block: true,
                    }}
                  />
                </Row>
              ) : (
                <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MT1, CommonStyles.Space.PH1]}>
                  <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS16]}>Pilih produk</Text>
                </Row>
              )}
            </Column>
          </Row>
        </>
      ) : (
        <View style={CommonStyles.Space.PV1} />
      )}
    </Column>
  );

  const renderSelectProduct = () => (
    <Column>
      <Row style={[CommonStyles.Layout.fullWidth]}>
        <Column style={[CommonStyles.Space.PH1, CommonStyles.Space.PT1]}>
          <Row>
            <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS16]}>Pilih Produk</Text>
          </Row>
          <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, CommonStyles.Space.MV1]} />
          {filteredProducts?.map((item) => (
            <View key={item.id} style={CommonStyles.Layout.fullWidth}>
              <TouchableOpacity style={CommonStyles.Layout.fullWidth} onPress={() => selectProduct(item)}>
                <Row key={item.id} style={CommonStyles.Layout.fullWidth} align="center">
                  <Image source={{ uri: item.image }} style={styles.image} />
                  <Column>
                    <Row>
                      <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS16, CommonStyles.Typography.TLH24]}>{item.name}</Text>
                    </Row>
                  </Column>
                </Row>
              </TouchableOpacity>
              <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, CommonStyles.Space.MV1]} />
            </View>
          ))}
        </Column>
      </Row>
    </Column>
  );

  return (
    <DefaultLayout
      footer
      backButton={(
        <TouchableOpacity
          onPress={() => {
            setIsSelectProductPage(false);
            navigation.navigate('profile');
          }}
        >
          <Row style={[CommonStyles.Space.P1, { backgroundColor: COLORS.divider }]}>
            <MetaIcon name="back" color={COLORS.infoBlue} size={18} />
            <Text style={[CommonStyles.Typography.TinfoBlue, CommonStyles.Typography.TS14, CommonStyles.Typography.TRegular, CommonStyles.Space.ML0_5]}>Akun saya</Text>
          </Row>
        </TouchableOpacity>
      )}
      {...props}
    >
      <Row>
        {!isSelectProductPage && renderAddID()}
        {isSelectProductPage && renderSelectProduct()}
      </Row>
    </DefaultLayout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  products: state.product.list,
  productDestinations: state.productDestination,
});

const mapDispatchToProps = (dispatch: any) => ({
  addDestination: (destination: IRequestParamsDestination, callback?: () => void) => dispatch(Actions.productDestination.add(destination, callback)),
  removeDestination: (destId: string, callback?: () => void) => dispatch(Actions.productDestination.remove(destId, callback)),
  getAllProducts: () => dispatch(Actions.product.getList()),
  getProductdestination: () => dispatch(Actions.productDestination.getList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserDestinationScreen);
