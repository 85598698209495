import React from 'react';
import { ImageBackground, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { Avatar, Button, Divider, List, Text } from '@ui-kitten/components';
import moment from 'moment';
import Column from '../../components/grid/column';
import Row from '../../components/grid/row';
import { IScreenProps } from '../../interfaces/screen';
import DefaultLayout from '../../components/default-layout';
import { ReduxState } from '../../reduxers/reducers';
import Actions from '../../reduxers/actions';
import CommonStyles from '../../styles';
import MetaIcon from '../../components/icon';
import COLORS from '../../constants/color';
import IPointState from '../../interfaces/states/point';
import IUserState from '../../interfaces/states/user';
import { IPoint } from '../../interfaces/models/point';

import poin from '../../assets/images/poin.png';
import background from '../../assets/images/background.png';
import { FormatNumber } from '../../utils';

interface MainProps extends IScreenProps {
  point: IPointState;
  getPoints: () => void;

  user: IUserState;
}

const PointScreen = (props: MainProps): React.ReactElement => {
  const { navigation, getPoints, point, user } = props;

  React.useEffect(() => {
    getPoints();
  }, []);

  return (
    <DefaultLayout
      backButton={(
        <TouchableOpacity onPress={() => navigation.navigate('profile')}>
          <Row style={[CommonStyles.Space.P1, { backgroundColor: COLORS.divider }]}>
            <MetaIcon name="back" color={COLORS.infoBlue} size={18} />
            <Text style={[CommonStyles.Typography.TinfoBlue, CommonStyles.Typography.TS14, CommonStyles.Typography.TRegular, CommonStyles.Space.ML0_5]}>Akun saya</Text>
          </Row>
        </TouchableOpacity>
      )}
      footer
      {...props}
    >
      <Row style={[CommonStyles.Layout.fullWidth]}>
        <Column>
          <Row style={[CommonStyles.Layout.fullWidth]}>
            <ImageBackground resizeMode="cover" source={background} style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PH1, CommonStyles.Space.PV2]}>
              <Column>
                <Row>
                  <Text style={[CommonStyles.Typography.TS18, CommonStyles.Typography.TBold]}>Poin yang dapat diredeem</Text>
                </Row>
                <Row align="center" style={[CommonStyles.Space.MV1]}>
                  <Avatar source={poin} style={[{ width: 32, height: 32 }, CommonStyles.Space.MR1]} />
                  <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS32]}>{`${user.data?.point} poin`}</Text>
                </Row>
                <Row>
                  <Text style={[CommonStyles.Typography.TS18, CommonStyles.Typography.TBold]}>Riwayat Poin</Text>
                </Row>
              </Column>
            </ImageBackground>
          </Row>
          <List
            style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.transparent]}
            data={point.list}
            renderItem={({ item }: { item: IPoint }) => (
              <Column style={[CommonStyles.Space.PH1]}>
                <Row style={[CommonStyles.Layout.fullWidth]} align="center">
                  <Column>
                    <Text style={[CommonStyles.Typography.TS16, CommonStyles.Typography.TSemiBold]}>{item.reason}</Text>
                    <Text style={[CommonStyles.Typography.TS14, CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackTwo]}>{moment.unix(item.createdAt).format('LLL')}</Text>
                  </Column>
                  <Text style={[CommonStyles.Typography.TS16, CommonStyles.Typography.TSemiBold]} status="info">
                    {FormatNumber(item.amount)}
                  </Text>
                </Row>
                <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MV1]} />
              </Column>
            )}
            ListEmptyComponent={(
              <Column style={[CommonStyles.Space.P1, CommonStyles.Background.white, CommonStyles.Space.MB1]}>
                <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MT3]}>
                  <Column align="center">
                    <MetaIcon name="empty-order" size={44} />
                  </Column>
                </Row>
                <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB3]}>
                  <Column align="center">
                    <Text style={[CommonStyles.Typography.TS16, CommonStyles.Typography.TSemiBolds]}>Tidak ada riwayat poin</Text>
                  </Column>
                </Row>
                <Row style={[CommonStyles.Layout.fullWidth]}>
                  <Button status="info" style={[CommonStyles.Layout.fullWidth]} onPress={() => navigation.navigate('home')}>
                    {() => <Text style={[CommonStyles.Typography.Twhite, CommonStyles.Typography.TS16, CommonStyles.Typography.TSemiBold]}>Kembali ke Homepage</Text>}
                  </Button>
                </Row>
              </Column>
            )}
          />
        </Column>
      </Row>
    </DefaultLayout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  point: state.point,
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  getPoints: () => dispatch(Actions.point.getList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PointScreen);
