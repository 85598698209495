import { Reducer } from 'redux';
import IProductItemState from '../../interfaces/states/product-item';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';

const initialState: IProductItemState = { ...BaseStateDefault };

const reducer: Reducer<any> = (state: IProductItemState = initialState, action): IProductItemState => {
  switch (action.type) {
    case ActionTypes.productItem.PRODUCT_ITEM_GET_LIST_REQUEST:
    case ActionTypes.inquiry.INQUIRY_BY_PRODUCT_TYPE_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined,
      };
    }
    case ActionTypes.productItem.PRODUCT_ITEM_GET_LIST_FAILURE: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }
    case ActionTypes.productItem.PRODUCT_ITEM_GET_LIST_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload,
      };
    }
    case ActionTypes.inquiry.INQUIRY_BY_PRODUCT_TYPE_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
