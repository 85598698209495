import { Reducer } from 'redux';
import IPointState from '../../interfaces/states/point';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';

const initialState: IPointState = { ...BaseStateDefault };

const reducer: Reducer<any> = (state: IPointState = initialState, action): IPointState => {
  switch (action.type) {
    case ActionTypes.point.POINT_GET_LIST_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined,
      };
    }
    case ActionTypes.point.POINT_GET_LIST_FAILURE: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }
    case ActionTypes.point.POINT_GET_LIST_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
