const PRODUCT_DESTINATION_GET_LIST_REQUEST = 'PRODUCT_DESTINATION_GET_LIST_REQUEST';
const PRODUCT_DESTINATION_GET_LIST_SUCCESS = 'PRODUCT_DESTINATION_GET_LIST_SUCCESS';
const PRODUCT_DESTINATION_GET_LIST_FAILURE = 'PRODUCT_DESTINATION_GET_LIST_FAILURE';

const ADD_PRODUCT_DESTINATION_REQUEST = 'ADD_PRODUCT_DESTINATION_REQUEST';
const ADD_PRODUCT_DESTINATION_SUCCESS = 'ADD_PRODUCT_DESTINATION_SUCCESS';
const ADD_PRODUCT_DESTINATION_FAILURE = 'ADD_PRODUCT_DESTINATION_FAILURE';

const REMOVE_PRODUCT_DESTINATION_REQUEST = 'REMOVE_PRODUCT_DESTINATION_REQUEST';
const REMOVE_PRODUCT_DESTINATION_SUCCESS = 'REMOVE_PRODUCT_DESTINATION_SUCCESS';
const REMOVE_PRODUCT_DESTINATION_FAILURE = 'REMOVE_PRODUCT_DESTINATION_FAILURE';

export default {
  PRODUCT_DESTINATION_GET_LIST_REQUEST,
  PRODUCT_DESTINATION_GET_LIST_FAILURE,
  PRODUCT_DESTINATION_GET_LIST_SUCCESS,

  ADD_PRODUCT_DESTINATION_REQUEST,
  ADD_PRODUCT_DESTINATION_SUCCESS,
  ADD_PRODUCT_DESTINATION_FAILURE,

  REMOVE_PRODUCT_DESTINATION_REQUEST,
  REMOVE_PRODUCT_DESTINATION_SUCCESS,
  REMOVE_PRODUCT_DESTINATION_FAILURE,
};
