import { StyleSheet, Dimensions } from 'react-native';
import COLORS from '../constants/color';
import { SCREEN_MAX_WIDTH } from '../constants';

const { height } = Dimensions.get('window');
const Layout = StyleSheet.create({
  wrapper: {
    maxWidth: SCREEN_MAX_WIDTH,
    width: '100%',
    marginHorizontal: 'auto',
    backgroundColor: COLORS.white,
    flex: 1,
    maxHeight: height - 56,
    overflowY: 'visible',
  },
  full: {
    flex: 1,
  },
  fullWidth: {
    width: '100%',
  },
  column: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  justifySpace: {
    justifyContent: 'space-between',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  alignSpace: {
    alignItems: 'stretch',
  },
});

export default Layout;
