import { combineReducers } from 'redux';
import metadata from './metadata';
import inquiry from './inquiry';
import order from './order';
import paymentMethod from './payment-method';
import point from './point';
import product from './product';
import productDestination from './product-destination';
import productItem from './product-item';
import productType from './product-kind';
import user from './user';
import IOrderState from '../../interfaces/states/order';
import IPaymentMethodState from '../../interfaces/states/payment-method';
import IProductState from '../../interfaces/states/product';
import IProductDestinationState from '../../interfaces/states/product-destination';
import IProductItemState from '../../interfaces/states/product-item';
import IProductTypeState from '../../interfaces/states/product-type';
import IUserState from '../../interfaces/states/user';
import IMetadataState from '../../interfaces/states/metadata';
import IInquiryState from '../../interfaces/states/inquiry';
import IPointState from '../../interfaces/states/point';

export interface ReduxState {
  metadata: IMetadataState;
  inquiry: IInquiryState;
  order: IOrderState;
  paymentMethod: IPaymentMethodState;
  point: IPointState;
  product: IProductState;
  productDestination: IProductDestinationState;
  productItem: IProductItemState;
  productType: IProductTypeState;
  user: IUserState;
}

export const rootReducer = combineReducers<ReduxState>({
  metadata,
  inquiry,
  order,
  paymentMethod,
  point,
  product,
  productDestination,
  productItem,
  productType,
  user,
});
