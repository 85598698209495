import { Button, Divider, Input, Modal, Popover, Text } from '@ui-kitten/components';
import React from 'react';
import { TouchableOpacity, ViewStyle } from 'react-native';
import Column from '../grid/column';
import Row from '../grid/row';
import Commonstyles from '../../styles';
import Styles from './index.style';
import MetaIcon from '../icon';
import COLORS from '../../constants/color';
import { IsOnlyNumber, IsValidEmail } from '../../utils';
import { PHONE_LABELS } from '../../constants';

interface IFieldProps {
  label: string | React.ReactNode;
  name: string;
  placeholder?: string;
  caption?: string;
  type: string;
  required?: boolean;
  min?: number;
  value?: string | number;
  helpContent?: string | React.ReactNode;
  options?: Array<string | number>;
  autoselect?: boolean;
  rightAction?: React.ReactNode;
  leftAction?: React.ReactNode;
  detailContent?: React.ReactNode;
  onSubmit?: (value: string) => void;
}

interface IFieldStates extends IFieldProps {
  secured?: boolean;
  error?: string;
  showHelp?: boolean;
  isActive?: boolean;
}

interface IButtonProps {
  text: string | React.ReactNode;
  action: (data: IDefaultFormValue | undefined) => void;
  status?: string;
  appearance?: string;
  textColor?: string;
  block?: boolean;
}

export interface IDefaultFormValue {
  [key: string]: string | number;
}

export interface IDefaultFormProps {
  socials?: Array<IButtonProps>;
  fields?: Array<IFieldProps>;
  button?: IButtonProps;
  alert?: string;
  style?: ViewStyle | Array<ViewStyle>;
}

interface IDefaultFormStates {
  fields: Array<IFieldStates>;
  showOptionsModal: boolean;
  activeField?: IFieldStates;
}

export default class DefaultForm extends React.Component<IDefaultFormProps, IDefaultFormStates> {
  fieldRefs: { [key: string]: any } = {};

  constructor(props: IDefaultFormProps) {
    super(props);
    this.state = {
      fields: [],
      showOptionsModal: false,
    };
  }

  componentDidMount = () => {
    this.initFields();
  };

  componentDidUpdate = (prevProps: IDefaultFormProps) => {
    if (this.props !== prevProps) {
      this.initFields();
    }
  };

  initFields = () => {
    this.setState((state) => ({
      fields: this.props.fields
        ? this.props.fields.map((f) => {
          const i = state.fields.findIndex((sf) => sf.name === f.name);
          let { value } = f;
          if (i >= 0 && state.fields[i] && state.fields[i].value) {
            value = state.fields[i].value;
          } else if (f.options && f.options.length > 0 && f.autoselect) {
            [value] = f.options;
          }
          if (PHONE_LABELS.includes(this.truncatedName(f.name)) && value) {
            const idx = value
              .toString()
              .split('')
              .findIndex((x) => x === '8');
            value = value.toString().substring(idx, value.toString().length);
          }
          return { ...f, secured: true, value };
        })
        : [],
    }));
  };

  validate = (onFinish?: () => void) => {
    const { fields } = this.state;
    fields.forEach((f) => {
      f.error = undefined;
      if (f.required && (!f.value || f.value?.toString().trim().length === 0)) {
        f.error = `${f.label} harus diisi`;
      }
      if (f.min && (!f.value || f.value?.toString().trim().length < f.min)) {
        f.error = `${f.label} harus lebih dari ${f.min} karakter`;
      }
      if (f.type === 'email' && f.value && !IsValidEmail(f.value.toString())) {
        f.error = `${f.label} bukan email valid`;
      }
      if (['numeric', 'number'].includes(f.type) && f.value && !IsOnlyNumber(f.value.toString())) {
        f.error = `${f.label} bukan numerik`;
      }
      if (PHONE_LABELS.includes(this.truncatedName(f.name)) && f.value && !f.value.toString().startsWith('8')) {
        f.error = `${f.label} harus diawali angka 8`;
      }
    });

    this.setState(
      {
        fields,
      },
      () => {
        if (onFinish && fields.map((x) => x.error).join('').length === 0) {
          onFinish();
        }
      },
    );
  };

  prepareSubmit = (onFinish?: (values: IDefaultFormValue) => void) => {
    this.validate(() => {
      const values = this.getValues();
      if (onFinish && Object.values(values).filter((x) => x).length > 0) onFinish(values);
    });
  };

  onSubmit = () => {
    const { button } = this.props;
    this.prepareSubmit((values) => {
      button?.action(values);
    });
  };

  getValues = () => {
    const { fields } = this.state;
    const values: IDefaultFormValue = {};
    fields.forEach((f) => {
      if (PHONE_LABELS.includes(this.truncatedName(f.name))) {
        values[f.name] = `+62${f.value || ''}`;
      } else {
        values[f.name] = f.value || '';
      }
    });
    return values;
  };

  getKeyboardType = (type: string) => {
    switch (type) {
      case 'email':
        return 'email-address';
      case 'number':
      case 'numeric': {
        return 'phone-pad';
      }
      default:
        return 'default';
    }
  };

  truncatedName = (name?: string) => {
    if (!name) return '';
    return name.toString().toLowerCase().split('_').join(' ');
  };

  renderField = (f: IFieldStates) => {
    const { fields } = this.state;
    const i = fields.findIndex((x) => x.name === f.name);
    return (
      <Row key={f.name} style={[Commonstyles.Space.PH1, Commonstyles.Space.MT1, Commonstyles.Layout.fullWidth]}>
        <Column>
          <Input
            ref={(input) => {
              this.fieldRefs[f.name] = input;
            }}
            style={[Commonstyles.Layout.fullWidth]}
            label={(props) => (
              <Text style={[Commonstyles.Space.MB0_5]}>
                {typeof f.label === 'string' ? (
                  <Text {...props} style={[Commonstyles.Typography.TS14, Commonstyles.Typography.TSemiBold, Commonstyles.Typography.TblackTwo]}>
                    {f.required ? (
                      <Text status="danger" style={[Commonstyles.Typography.TS10]}>
                        *
                      </Text>
                    ) : (
                      <></>
                    )}
                    {f.label}
                  </Text>
                ) : (
                  <>{f.label}</>
                )}
                {f.helpContent ? (
                  <Popover
                    anchor={() => (
                      <TouchableOpacity
                        style={[Commonstyles.Space.ML0_5]}
                        onPress={() => {
                          fields[i].showHelp = !fields[i].showHelp;
                          this.setState({ fields });
                        }}
                      >
                        <MetaIcon name="info" size={14} color={COLORS.infoBlue} />
                      </TouchableOpacity>
                    )}
                    visible={f.showHelp}
                    placement="bottom start"
                    onBackdropPress={() => {
                      fields[i].showHelp = !fields[i].showHelp;
                      this.setState({ fields });
                    }}
                  >
                    {typeof f.helpContent === 'string' ? <Text>{f.helpContent}</Text> : <>{f.helpContent}</>}
                  </Popover>
                ) : (
                  <></>
                )}
              </Text>
            )}
            placeholder={f.placeholder}
            caption={
              f.error
                ? () => (
                  <Text status="danger" style={[Commonstyles.Typography.TS10]}>
                    {f.error}
                  </Text>
                )
                : f.caption
            }
            accessoryRight={() => (f.rightAction ? (
              <>{f.rightAction}</>
            ) : f.type === 'password' ? (
              <TouchableOpacity
                onPress={() => {
                  fields[i].secured = !fields[i].secured;
                  this.setState({ fields });
                }}
              >
                <MetaIcon name={f.secured ? 'pass-hidden' : 'pass-peek'} size={12} color={COLORS.blackTwo} />
              </TouchableOpacity>
            ) : (
              <></>
            ))}
            accessoryLeft={() => {
              if (f.leftAction) {
                return <>{f.leftAction}</>;
              }
              if (PHONE_LABELS.includes(this.truncatedName(f.name))) {
                return <Text style={[Commonstyles.Typography.TS16, Commonstyles.Typography.TRegular]}>+62</Text>;
              }
              return <></>;
            }}
            secureTextEntry={f.type === 'password' && f.secured}
            value={f.value ? f.value.toString() : ''}
            onChangeText={(value) => {
              if (f.isActive) {
                const { activeField } = this.state;
                if (activeField) {
                  activeField.value = value;
                  this.setState({ activeField });
                }
              } else {
                fields[i].value = value;
                this.setState({ fields });
              }
            }}
            allowFontScaling
            status={f.error ? 'danger' : 'basic'}
            keyboardType={this.getKeyboardType(f.type)}
            onFocus={() => {
              const activeField = { ...f };
              if (activeField.options && activeField.options.length > 0) {
                activeField.rightAction = (
                  <TouchableOpacity
                    onPress={() => {
                      const idx = fields?.findIndex((x) => x.name === activeField.name);
                      if (idx >= 0) {
                        fields[idx].value = activeField.value;
                        this.setState({ fields, showOptionsModal: false }, () => this.prepareSubmit((values) => {
                          if (activeField.onSubmit && values[activeField.name]) activeField.onSubmit(values[activeField.name].toString());
                        }));
                      }
                    }}
                  >
                    <MetaIcon name="right" size={12} />
                  </TouchableOpacity>
                );
                activeField.detailContent = undefined;
                activeField.isActive = true;
                this.setState({ showOptionsModal: true, activeField });
                if (!f.isActive && this.fieldRefs[f.name]) this.fieldRefs[f.name].blur();
              }
            }}
            onSubmitEditing={(e) => {
              const idx = fields?.findIndex((x) => x.name === f.name);
              if (idx >= 0 && e.nativeEvent && e.nativeEvent.text && e.nativeEvent.text.length > 0) {
                fields[idx].value = e.nativeEvent.text;
                this.setState({ fields, showOptionsModal: false }, () => this.prepareSubmit((values) => {
                  if (f.onSubmit && values[f.name]) f.onSubmit(values[f.name].toString());
                }));
              }
            }}
            onBlur={(e) => {
              const idx = fields?.findIndex((x) => x.name === f.name);
              if (idx >= 0 && e.nativeEvent && e.nativeEvent.text && e.nativeEvent.text.length > 0) {
                fields[idx].value = e.nativeEvent.text;
                this.setState({ fields }, () => this.prepareSubmit((values) => {
                  if (f.onSubmit && values[f.name]) f.onSubmit(values[f.name].toString());
                }));
              }
            }}
          />
          {f.detailContent}
        </Column>
      </Row>
    );
  };

  render() {
    const { button, alert, socials, style } = this.props;
    const { fields, showOptionsModal, activeField } = this.state;
    const styles = style ? (Array.isArray(style) ? style : [style]) : [];

    return (
      <Row style={[Commonstyles.Layout.fullWidth, ...styles]}>
        <Column>
          {alert && (
            <Row style={[Commonstyles.Layout.fullWidth, Commonstyles.Background.blueBG]}>
              <Column align="center" style={[Commonstyles.Space.P1]}>
                <Text status="info" style={[Commonstyles.Typography.TSemiBold]}>
                  {alert}
                </Text>
              </Column>
            </Row>
          )}
          {socials && socials.length > 0 && (
            <Row style={[Commonstyles.Space.PH1, Commonstyles.Space.MT1, Commonstyles.Layout.fullWidth]}>
              {socials.map((s, i) => (
                <Column key={s.text?.toString()} align="center" style={[i < socials.length - 1 ? Commonstyles.Space.MR1 : undefined]}>
                  <Button onPress={() => s.action} status={s.status || 'info'} style={[Commonstyles.Layout.fullWidth]}>
                    {() => (s?.text && typeof s?.text === 'string' ? (
                      <Text style={[Commonstyles.Typography.TSemiBold, Commonstyles.Typography.TS14, Commonstyles.Typography.Twhite]}>{s.text}</Text>
                    ) : (
                      <>{s?.text}</>
                    ))}
                  </Button>
                </Column>
              ))}
            </Row>
          )}
          {fields.map((f) => this.renderField(f))}
          {button ? (
            <Row style={[Commonstyles.Space.PH1, Commonstyles.Space.MT2, Commonstyles.Layout.fullWidth]}>
              <Column align="center">
                <Button onPress={this.onSubmit} status={button?.status || 'info'} appearance={button?.appearance || 'filled'} style={[button.block ? Commonstyles.Layout.fullWidth : undefined]}>
                  {() => (button?.text && typeof button?.text === 'string' ? (
                    <Text
                      style={[
                        Commonstyles.Typography.TSemiBold,
                        Commonstyles.Typography.TS14,
                        Commonstyles.Space.PH4,
                        button?.textColor ? { color: button.textColor } : Commonstyles.Typography.Twhite,
                      ]}
                    >
                      {button?.text}
                    </Text>
                  ) : (
                    <>{button?.text}</>
                  ))}
                </Button>
              </Column>
            </Row>
          ) : (
            <></>
          )}
          <Modal visible={showOptionsModal} backdropStyle={[Styles.modalBackdrop]} style={[Styles.modal]}>
            <Row align="center" style={[Commonstyles.Space.P1]}>
              <Column>
                <Text style={[Commonstyles.Typography.TSemiBold, Commonstyles.Typography.TS16]}>{`Masukkan ${activeField?.label}`}</Text>
              </Column>
              <TouchableOpacity onPress={() => this.setState({ showOptionsModal: false, activeField: undefined })}>
                <MetaIcon name="close-overlay" size={20} />
              </TouchableOpacity>
            </Row>
            <Divider style={[Commonstyles.Space.MH1]} />
            {activeField ? this.renderField(activeField) : <></>}
            <Row align="center" style={[Commonstyles.Space.P1]}>
              <Column>
                <Row style={[Commonstyles.Space.MB1]}>
                  <Text style={[Commonstyles.Typography.TSemiBold, Commonstyles.Typography.TS16]}>{`${activeField?.label} Tersimpan`}</Text>
                </Row>
                {activeField?.options?.map((o) => (
                  <Row align="center" key={o} style={[Commonstyles.Layout.fullWidth]}>
                    <Button
                      onPress={() => {
                        const i = fields.findIndex((x) => x.name === activeField.name);
                        if (i >= 0) {
                          let val = o.toString();
                          if (fields[i].name && PHONE_LABELS.includes(this.truncatedName(fields[i].name))) {
                            const idx = o
                              .toString()
                              .split('')
                              .findIndex((x) => x === '8');
                            val = o.toString().substring(idx, o.toString().length);
                          }
                          fields[i].value = val;
                          this.setState({ fields, showOptionsModal: false }, () => this.prepareSubmit((values) => {
                            if (activeField.onSubmit && values[activeField.name]) activeField.onSubmit(values[activeField.name].toString());
                          }));
                        }
                      }}
                      style={[Commonstyles.Layout.fullWidth, Commonstyles.Space.PH0, Commonstyles.Layout.justifyStart]}
                      appearance="ghost"
                    >
                      {() => (
                        <Text style={[Commonstyles.Typography.TRegular, Commonstyles.Typography.TS16]}>
                          <MetaIcon name="save" size={14} style={[Commonstyles.Space.MR0_5]} />
                          {o}
                        </Text>
                      )}
                    </Button>
                  </Row>
                ))}
              </Column>
            </Row>
          </Modal>
        </Column>
      </Row>
    );
  }
}
