import React from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, Divider, List, Modal, Text } from '@ui-kitten/components';
import { TouchableOpacity } from 'react-native';
import DefaultLayout from '../../components/default-layout';
import { ReduxState } from '../../reduxers/reducers';
import { IScreenProps } from '../../interfaces/screen';
import Row from '../../components/grid/row';
import CommonStyles from '../../styles';
import Column from '../../components/grid/column';
import MetaIcon from '../../components/icon';
import IUserState from '../../interfaces/states/user';
import COLORS from '../../constants/color';
import Actions from '../../reduxers/actions';
import { PADDING } from '../../constants';
import IProductDestinationState from '../../interfaces/states/product-destination';
import Styles from './index.style';
import DefaultForm, { IDefaultFormValue } from '../../components/form';
import { IUserUpdateRequest } from '../../interfaces/models/user';
import poin from '../../assets/images/poin.png';
import IProductState from '../../interfaces/states/product';
import IProduct from '../../interfaces/models/product';
import IProductDestination from '../../interfaces/models/product-destination';

interface MainProps extends IScreenProps {
  user: IUserState;
  productDestination: IProductDestinationState;
  products: IProductState;

  getProductdestination: () => Promise<any>;
  getProductList: () => Promise<any>;
  logout: () => Promise<any>;

  update: (data: IUserUpdateRequest, callback?: () => void) => Promise<any>;
}

const ProfileScreen = (props: MainProps): React.ReactElement => {
  const { user, navigation, productDestination, products, getProductdestination, logout, update, getProductList } = props;
  const [nameModalVisible, setNameModalVisible] = React.useState(false);
  const [logoutModalVisible, setLogoutModalVisible] = React.useState(false);

  React.useEffect(() => {
    if (user.data) {
      getProductdestination();
      getProductList();
    }
    if (user.data?.fullName?.length === 0) setNameModalVisible(true);
    else setNameModalVisible(false);
  }, [user.data?.fullName]);

  const saveName = (data?: IDefaultFormValue) => {
    if (data && user.data?.email) {
      const req: IUserUpdateRequest = {
        email: user.data?.email,
        billingEmail: user.data?.email,
        fullName: data.fullName as string,
      };
      update(req);
    }
  };

  const filteredProductDestination = products.list?.filter((p) => {
    p.destinationArray = productDestination.list?.filter((d) => d?.isActive && d?.product?.id === p.id);
    return (p?.destinationArray?.length || 0) > 0;
  });

  return (
    <DefaultLayout footer backButton {...props}>
      <Row>
        <Column>
          <Row style={[CommonStyles.Layout.fullWidth]}>
            <Column style={[CommonStyles.Space.PH1, CommonStyles.Space.PT1]}>
              <Row style={[CommonStyles.Layout.fullWidth]} align="center">
                <Column justify="center">
                  <Row align="center">
                    <MetaIcon name="profile" color={COLORS.blackOne} size={33} />
                    <Column style={[CommonStyles.Space.ML1]}>
                      <Row style={[CommonStyles.Space.MB0_5]}>
                        <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS16]}>
                          {`Halo, ${user.data?.fullName || user.data?.email || 'Anonim'}`}
                        </Text>
                      </Row>
                      <TouchableOpacity onPress={() => navigation.navigate('point')}>
                        <Row>
                          <Avatar source={poin} style={[{ width: 18, height: 18 }, CommonStyles.Space.MR0_5]} />
                          <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS14]} status="primary">
                            {`${user.data?.point} poin`}
                          </Text>
                        </Row>
                      </TouchableOpacity>
                    </Column>
                  </Row>
                </Column>
                <Column align="end">
                  <Button appearance="ghost" status="primary" onPress={() => setLogoutModalVisible(true)}>
                    Keluar
                  </Button>
                </Column>
              </Row>
              <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, CommonStyles.Space.MV1]} />
              <List
                style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.transparent]}
                data={[
                  { label: 'Email', value: user.data?.email || 'Mohon isi email' },
                  { label: 'Nomor Telepon', value: user.data?.phoneNumber || 'Mohon isi nomor telepon', action: () => navigation.navigate('edit-profile') },
                  { value: 'Riwayat Transaksi', action: () => navigation.navigate('order-list') },
                ]}
                renderItem={({ item, index }) => (
                  <TouchableOpacity onPress={() => (item.action ? item.action() : null)}>
                    <Row style={[CommonStyles.Layout.fullWidth]}>
                      <Column>
                        <Row key={index} style={[CommonStyles.Layout.fullWidth]} align="center">
                          <Column>
                            <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS14, CommonStyles.Typography.TLH24]}>{item.label}</Text>
                            <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS16, CommonStyles.Typography.TLH24]}>{item.value}</Text>
                          </Column>
                          {item.action && <MetaIcon name="arrow" color={COLORS.blackOne} size={16} style={[CommonStyles.Space.ML1]} />}
                        </Row>
                        {index < 2 && <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, CommonStyles.Space.MV1]} />}
                      </Column>
                    </Row>
                  </TouchableOpacity>
                )}
              />
            </Column>
          </Row>
          <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, CommonStyles.Space.MT1, { height: PADDING / 2 }]} />
          <Row style={[CommonStyles.Layout.fullWidth]}>
            <Column style={[CommonStyles.Space.PH1, CommonStyles.Space.PT1]}>
              <Row>
                <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS16]}>Simpan ID</Text>
              </Row>
              <Row style={[CommonStyles.Space.MT1]}>
                <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS14]}>
                  Isi ID atau lakukan transaksi, maka sistem akan menyimpan ID anda untuk transaksi berikutnya
                </Text>
              </Row>
              <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, CommonStyles.Space.MV1]} />
              <List
                style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.transparent]}
                data={filteredProductDestination}
                renderItem={({ item, index }: { item: IProduct; index: number }) => (
                  <TouchableOpacity onPress={() => navigation.navigate('add-user-destination', { productId: item.id.toString() })}>
                    <Row style={[CommonStyles.Layout.fullWidth]}>
                      <Column>
                        <Row key={index} style={[CommonStyles.Layout.fullWidth]} align="center">
                          <Avatar style={[CommonStyles.Space.MR1]} shape="square" source={{ uri: item.image }} />
                          <Column>
                            <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS14, CommonStyles.Typography.TLH24]}>{item.name}</Text>
                            <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackOne, CommonStyles.Typography.TS14, CommonStyles.Typography.TLH24]}>
                              {(item.destinationArray?.length || 0) > 1 ? (
                                <>
                                  <MetaIcon name="save" color={COLORS.blackOne} size={12} style={Styles.icon} />
                                  {`${item.destinationArray?.length} ID tersimpan`}
                                </>
                              ) : (
                                (item?.destinationArray as Array<IProductDestination>)[0].destination
                              )}
                            </Text>
                          </Column>
                          <MetaIcon name="arrow" color={COLORS.blackOne} size={16} style={[CommonStyles.Space.ML1]} />
                        </Row>
                        {index < (productDestination.list || []).length && <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.divider, CommonStyles.Space.MV1]} />}
                      </Column>
                    </Row>
                  </TouchableOpacity>
                )}
              />
              <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB2]} justify="center">
                <Button
                  appearance="outline"
                  status="info"
                  onPress={() => navigation.navigate('add-user-destination', { productId: undefined })}
                  style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.white]}
                >
                  {() => <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS14, CommonStyles.Typography.TinfoBlue]}>Tambahkan ID</Text>}
                </Button>
              </Row>
            </Column>
          </Row>
        </Column>
      </Row>
      <Modal visible={nameModalVisible} backdropStyle={[CommonStyles.Background.backdropBG, Styles.modalBackdrop]} onBackdropPress={() => null}>
        <Row style={[CommonStyles.Background.white, CommonStyles.Layout.fullWidth]}>
          <Column style={[CommonStyles.Space.PB1]}>
            <Row style={[CommonStyles.Space.P1]}>
              <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Silakan masukkan nama profil Kamu</Text>
            </Row>
            <DefaultForm
              fields={[{ label: 'Nama Profil', name: 'fullName', type: 'text', required: true }]}
              button={{
                text: (
                  <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS14, CommonStyles.Typography.TinfoBlue]}>
                    Simpan nama profil
                    <MetaIcon name="arrow" size={14} color={COLORS.infoBlue} style={[CommonStyles.Space.ML1]} />
                  </Text>
                ),
                action: saveName,
                appearance: 'ghost',
              }}
            />
          </Column>
        </Row>
      </Modal>
      <Modal visible={logoutModalVisible} backdropStyle={[CommonStyles.Background.backdropBG, Styles.modalBackdrop]} onBackdropPress={() => null}>
        <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.P1, CommonStyles.Background.white]}>
          <Column>
            <Row style={[CommonStyles.Layout.fullWidth]}>
              <Column>
                <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Konfirmasi</Text>
              </Column>
              <TouchableOpacity onPress={() => setLogoutModalVisible(false)}>
                <MetaIcon name="close-overlay" size={20} />
              </TouchableOpacity>
            </Row>
            <Divider style={[CommonStyles.Space.MV1, CommonStyles.Layout.fullWidth]} />
            <Row style={[CommonStyles.Space.MB1]}>
              <Text style={[CommonStyles.Typography.TRegular]}>Apakah Kamu yakin untuk keluar dari Metazone sekarang?</Text>
            </Row>
            <Row style={[CommonStyles.Layout.fullWidth]} justify="end">
              <Button appearance="outline" onPress={logout}>
                {() => <Text style={[CommonStyles.Typography.TRegular]}>Saya yakin</Text>}
              </Button>
            </Row>
          </Column>
        </Row>
      </Modal>
    </DefaultLayout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  user: state.user,
  products: state.product,
  productDestination: state.productDestination,
});

const mapDispatchToProps = (dispatch: any) => ({
  getProductdestination: () => dispatch(Actions.productDestination.getList()),
  getProductList: () => dispatch(Actions.product.getList()),
  logout: () => dispatch(Actions.user.logout()),
  update: (data: IUserUpdateRequest, callback?: () => void) => dispatch(Actions.user.update(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
