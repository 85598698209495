import React from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, Divider, List, Modal, Text } from '@ui-kitten/components';
import { TouchableOpacity, View, ViewStyle } from 'react-native';
import moment from 'moment';
import DefaultLayout from '../../components/default-layout';
import { ReduxState } from '../../reduxers/reducers';
import { IScreenProps } from '../../interfaces/screen';
import Actions from '../../reduxers/actions';
import IOrderState from '../../interfaces/states/order';
import CommonStyles from '../../styles';
import Row from '../../components/grid/row';
import Column from '../../components/grid/column';
import { PADDING, PHONE_LABELS, STATUS, TRANSLATED_STATUS } from '../../constants';
import { Capitalize, FormatNumber } from '../../utils';
import Styles from './index.style';
import { IProductDestinationJSON } from '../../interfaces/models/product';
import IUserState from '../../interfaces/states/user';
import MetaIcon from '../../components/icon';
import * as Navigation from '../../utils/navigation';
import COLORS from '../../constants/color';
import IMetadataState from '../../interfaces/states/metadata';
import { IFAQMeta, IPaymentMethodMeta } from '../../interfaces/models/metadata';
import FAQCard from '../../components/faq-card';

interface MainProps extends IScreenProps {
  order: IOrderState;
  getOrderByReceiptId: (receiptId: string, callback?: () => void) => void;
  cancelOrder: (receiptId: string, callback?: () => void) => void;

  user: IUserState;

  metadata: IMetadataState;
  getMetadata: (type: string) => void;
}

interface MainStates {
  showCancelModal: boolean;
}

class OrderScreen extends React.Component<MainProps, MainStates> {
  timer: ReturnType<typeof setInterval> | undefined;

  constructor(props: MainProps) {
    super(props);
    this.state = {
      showCancelModal: false,
    };
  }

  componentDidMount = () => {
    this.refresh();
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.refresh();
      }, 10000);
    }
    const { getMetadata } = this.props;
    getMetadata('payment-method');
  };

  componentDidUpdate = (prevProps: MainProps) => {
    if (prevProps.route.params?.receiptId !== this.props.route.params?.receiptId) {
      this.refresh();
      this.props.getMetadata('payment-method');
    }
  };

  componentWillUnmount = () => {
    this.unsetTimer();
  };

  unsetTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  };

  refresh = () => {
    const { getOrderByReceiptId, route } = this.props;
    if (route.params?.receiptId && ['order', ''].includes(Navigation.current())) {
      getOrderByReceiptId(route.params.receiptId, () => {
        const {
          order: { data },
        } = this.props;
        if (
          data?.paymentStatus !== STATUS.Pending
          || (data?.deliveryStatus !== STATUS.Pending && data?.productItem?.product?.deliveryMethod?.methodId && data.productItem.product.deliveryMethod.methodId <= 1)
        ) {
          this.unsetTimer();
        }
      });
    }
  };

  mapDestinationValue = (label?: string) => {
    const {
      order: { data },
    } = this.props;
    if (label) {
      if (label.toLowerCase() === 'email') {
        return data?.user?.email || data?.user?.billingEmail;
      }
      if (PHONE_LABELS.includes(label.toLowerCase())) {
        return data?.user?.phoneNumber?.toString();
      }
    }
    return undefined;
  };

  renderPending = () => {
    const {
      order: { data },
      metadata,
    } = this.props;
    const destinations: Array<IProductDestinationJSON> = data?.productItem.product.destination ? JSON.parse(data?.productItem.product.destination) : [];
    const paymentMethodMeta = metadata.list?.find((x) => x.type === 'payment-method');
    const parsedPaymentMethodMeta: Array<IPaymentMethodMeta> = paymentMethodMeta && paymentMethodMeta.value ? JSON.parse(paymentMethodMeta.value) : [];
    const filteredPaymentMethodMeta = parsedPaymentMethodMeta.filter((x) => x.methodId === data?.paymentMethod.methodId);
    return (
      <>
        <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.P1]}>
          <Column>
            <Row>
              <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Rincian Pembayaran</Text>
            </Row>
            <Divider style={[CommonStyles.Space.MV1, CommonStyles.Layout.fullWidth]} />
            <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.P1, Styles.pendingBox]} justify="center">
              <Column>
                <Row justify="center" style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB1]}>
                  <Avatar size="giant" shape="square" source={{ uri: data?.paymentMethod.image }} style={[CommonStyles.Background.blackTwo]} />
                </Row>
                <Row justify="center" style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB1]}>
                  <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS14, CommonStyles.Typography.Tgrey]}>Kode unik pembayaran</Text>
                </Row>
                <Row justify="center" style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB2]}>
                  <Text style={[CommonStyles.Typography.TBold, CommonStyles.Typography.TS18]}>{data?.vaNumber || '-'}</Text>
                </Row>
                <Row justify="center" style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB1]}>
                  <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS14, CommonStyles.Typography.Tgrey]}>Jumlah Tagihan</Text>
                </Row>
                <Row justify="center" style={[CommonStyles.Layout.fullWidth]}>
                  <Text style={[CommonStyles.Typography.TBold, CommonStyles.Typography.TS18, CommonStyles.Typography.Tprice]}>{`Rp ${FormatNumber(data?.total || 0)}`}</Text>
                </Row>
              </Column>
            </Row>
            {this.renderInfo([
              {
                label: 'Status Pembayaran',
                value: Capitalize(TRANSLATED_STATUS[data?.paymentStatus || '']),
                valueTextStyle: [CommonStyles.Typography.Tprice],
                icon: <MetaIcon name="refresh" size={20} style={[CommonStyles.Space.ML0_5]} />,
              },
              { label: 'Produk', value: data?.productItem.name },
              ...destinations.map((d, i) => ({ label: d.label, value: this.mapDestinationValue(d.label) || (data?.destinations ? data?.destinations[i] : '-') })),
            ])}
          </Column>
        </Row>
        <Divider style={[{ height: PADDING / 2 }, CommonStyles.Background.Divider, CommonStyles.Layout.fullWidth, CommonStyles.Space.MV1]} />
        <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PH1, CommonStyles.Space.PT1]}>
          <Column>
            <Row>
              <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Cara Pembayaran</Text>
            </Row>
            <Divider style={[CommonStyles.Background.Divider, CommonStyles.Layout.fullWidth, CommonStyles.Space.MV1]} />
            <List
              style={[CommonStyles.Background.transparent, CommonStyles.Layout.fullWidth]}
              data={filteredPaymentMethodMeta.map((pm) => ({ q: pm.title, a: pm.steps }))}
              renderItem={({ item }: { item: IFAQMeta }) => <FAQCard faq={item} />}
            />
          </Column>
        </Row>
      </>
    );
  };

  renderNonPending = () => {
    const {
      order: { data },
    } = this.props;
    const destinations: Array<IProductDestinationJSON> = data?.productItem.product.destination ? JSON.parse(data?.productItem.product.destination) : [];
    return (
      <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.P1]}>
        <Column>
          <Row>
            <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Rincian Pembayaran</Text>
          </Row>
          <Divider style={[CommonStyles.Space.MV1, CommonStyles.Layout.fullWidth]} />
          <Row style={[CommonStyles.Layout.fullWidth]} justify="center">
            <MetaIcon
              name={[STATUS.Success, STATUS.Delivered].includes(data?.paymentStatus || '') ? 'tick' : 'cancel'}
              size={40}
              color={[STATUS.Success, STATUS.Delivered].includes(data?.paymentStatus || '') ? COLORS.green : COLORS.price}
            />
            <View style={[CommonStyles.Layout.column, CommonStyles.Space.ML1]}>
              <Row>
                <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16, CommonStyles.Typography.TLH24]}>
                  {`Transaksi ${data ? Capitalize(TRANSLATED_STATUS[data.paymentStatus]) : ''}`}
                </Text>
              </Row>
              <Row>
                <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS14, CommonStyles.Typography.Tgrey]}>{moment.unix(data?.createdAt || 0).format('LLL')}</Text>
              </Row>
            </View>
          </Row>
          <Divider style={[CommonStyles.Space.MV1, CommonStyles.Layout.fullWidth]} />
          <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PH1]}>
            <Column>
              <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS16]}>Produk & Harga (Termasuk biaya layanan)</Text>
            </Column>
          </Row>
          <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PH1, CommonStyles.Space.MT1]}>
            <Column>
              <Row>
                <Avatar size="small" style={[CommonStyles.BorderRadius.BR3]} source={{ uri: data?.productItem.product.image }} />
                <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16, CommonStyles.Space.ML0_5]}>{data?.productItem.product.name}</Text>
              </Row>
            </Column>
            <Column align="end">
              <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TRight, CommonStyles.Typography.TS16, CommonStyles.Typography.Tprice]}>
                {`Rp ${FormatNumber(data?.total || 0)}`}
              </Text>
            </Column>
          </Row>
          {data?.voucherCode ? (
            <>
              <Row style={[CommonStyles.Space.M1]}>
                <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS16]}>Kode Voucher</Text>
              </Row>
              <Row style={[CommonStyles.Space.PH1, CommonStyles.Layout.fullWidth]}>
                <Column style={[CommonStyles.Space.P2, CommonStyles.Background.divider, CommonStyles.BorderRadius.BR3]} align="center">
                  <Text style={[CommonStyles.Typography.TBold, CommonStyles.Typography.TS18]}>{data?.voucherCode || ''}</Text>
                </Column>
              </Row>
            </>
          ) : (
            <></>
          )}
          <Divider style={[CommonStyles.Space.MT1, CommonStyles.Layout.fullWidth]} />
          {this.renderInfo([
            ...destinations.map((d, i) => ({
              label: d.label,
              value: this.mapDestinationValue(d.label) || (data?.destinations ? data.destinations[i] : '-'),
            })),
            { label: 'Metode Pembayaran', value: data?.paymentMethod.methodName },
          ])}
        </Column>
      </Row>
    );
  };

  renderInfo = (infos: Array<{ label?: string; value?: string; icon?: React.ReactElement; valueTextStyle?: Array<ViewStyle> }>) => {
    const {
      user,
      order: { data },
      navigation,
      cancelOrder,
    } = this.props;
    const { showCancelModal } = this.state;
    const destinations: Array<IProductDestinationJSON> = data?.productItem.product.destination ? JSON.parse(data?.productItem.product.destination) : [];
    const emailDestIndex = destinations.findIndex((x) => x.label?.toLowerCase().includes('email'));
    return (
      <>
        {infos.map((x) => (
          <Row key={x.label} style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.P1]}>
            <Column align="start">
              <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS16]}>{x.label}</Text>
            </Column>
            <Column align="end">
              <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TRight, CommonStyles.Typography.TS16, ...(x.valueTextStyle || [])]}>{x.value}</Text>
            </Column>
          </Row>
        ))}
        {((emailDestIndex >= 0 && data?.destinations && data?.destinations[emailDestIndex]) || data?.user?.email || data?.user?.billingEmail) && (
          <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.P1, CommonStyles.Space.MB1]}>
            <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS16, CommonStyles.Typography.TCenter]}>
              {`Rincian order ${data?.receiptID} telah dikirimkan ke ${
                data?.user?.email || data?.user?.billingEmail || (emailDestIndex >= 0 && data?.destinations ? data?.destinations[emailDestIndex] : undefined) || '-'
              }`}
            </Text>
          </Row>
        )}
        {user.data && data?.paymentStatus === STATUS.Pending ? (
          <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PH1, CommonStyles.Space.MB1]} justify="center">
            <Button appearance="outline" status="danger" onPress={() => this.setState({ showCancelModal: true })}>
              {() => <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS14, CommonStyles.Typography.Tprice]}>Batalkan Transaksi</Text>}
            </Button>
          </Row>
        ) : (
          <></>
        )}
        {user.data ? (
          <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PH1]} justify="center">
            <Button appearance="outline" status="info" onPress={() => navigation.navigate('profile')}>
              {() => <Text style={[CommonStyles.Typography.TRegular, CommonStyles.Typography.TS14, CommonStyles.Typography.TinfoBlue]}>Kembali ke akun</Text>}
            </Button>
          </Row>
        ) : (
          <></>
        )}
        <Modal visible={showCancelModal} backdropStyle={[CommonStyles.Background.backdropBG, Styles.modalBackdrop]} onBackdropPress={() => null}>
          <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.P1, CommonStyles.Background.white]}>
            <Column>
              <Row style={[CommonStyles.Layout.fullWidth]}>
                <Column>
                  <Text style={[CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16]}>Konfirmasi</Text>
                </Column>
                <TouchableOpacity onPress={() => this.setState({ showCancelModal: false })}>
                  <MetaIcon name="close-overlay" size={20} />
                </TouchableOpacity>
              </Row>
              <Divider style={[CommonStyles.Space.MV1, CommonStyles.Layout.fullWidth]} />
              <Row style={[CommonStyles.Space.MB1]}>
                <Text style={[CommonStyles.Typography.TRegular]}>Apakah Kamu yakin untuk membatalkan transaksi ini?</Text>
              </Row>
              <Row style={[CommonStyles.Layout.fullWidth]} justify="end">
                <Button appearance="outline" onPress={() => (data?.receiptID ? cancelOrder(data?.receiptID, () => this.setState({ showCancelModal: false })) : undefined)}>
                  {() => <Text style={[CommonStyles.Typography.TRegular]}>Saya yakin</Text>}
                </Button>
              </Row>
            </Column>
          </Row>
        </Modal>
      </>
    );
  };

  render = () => {
    const { order } = this.props;
    return (
      <DefaultLayout backButton footer {...this.props}>
        {order.data?.paymentStatus === STATUS.Pending ? this.renderPending() : this.renderNonPending()}
      </DefaultLayout>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  order: state.order,
  user: state.user,
  metadata: state.metadata,
});

const mapDispatchToProps = (dispatch: any) => ({
  getOrderByReceiptId: (receiptId: string, callback?: () => void) => dispatch(Actions.order.getByReceiptId(receiptId, callback)),
  cancelOrder: (receiptId: string, callback?: () => void) => dispatch(Actions.order.cancel(receiptId, callback)),
  getMetadata: (type: string) => dispatch(Actions.metadata.getByType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderScreen);
