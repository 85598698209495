import Background from './background';
import BorderRadius from './border-radius';
import Layout from './layout';
import Space from './space';
import Typography from './typography';

export default {
  Background,
  BorderRadius,
  Layout,
  Space,
  Typography,
};
