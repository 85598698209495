import * as React from 'react';
import { Image, Dimensions, TouchableWithoutFeedback, Linking, Platform, View } from 'react-native';
import { ViewPager } from '@ui-kitten/components';
import { SCREEN_MAX_WIDTH } from '../../constants';
import Styles from './index.style';
import CommonStyles from '../../styles';
import Row from '../grid/row';
import MetaIcon from '../icon';
import COLORS from '../../constants/color';

interface IHeroSliderData {
  image: string;
  link?: string;
}
interface IProps {
  data: Array<IHeroSliderData>;
  interval?: number;
  autoplay?: boolean;
}

const screenWidth = Dimensions.get('window').width;
const bannerWidth = screenWidth > SCREEN_MAX_WIDTH ? SCREEN_MAX_WIDTH : screenWidth;
const bannerRatio = 138 / 360;

const HeroSlider = ({ data, interval, autoplay }: IProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const getNextIndex = () => (selectedIndex + 1) % data.length;
  const getPrevIndex = () => {
    const prev = selectedIndex - 1;
    return prev < 0 ? data.length + prev : prev;
  };
  const play = () => {
    if (autoplay) {
      return setInterval(() => {
        const nextIndex = getNextIndex();
        setSelectedIndex(nextIndex);
      }, interval);
    }
    return undefined;
  };

  React.useEffect(() => {
    let i = play();
    return () => {
      clearInterval(i);
      i = undefined;
    };
  }, [interval, autoplay, selectedIndex]);

  const handleClick = (d: IHeroSliderData) => {
    if (d.link) {
      if (Platform.OS === 'web') {
        window.open(d.link, '_blank');
      } else {
        Linking.openURL(d.link);
      }
    }
  };

  return (
    <View>
      <ViewPager selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)}>
        {data
          && data.map((d) => (
            <TouchableWithoutFeedback onPress={() => handleClick(d)} key={d.image}>
              <Image source={{ uri: d.image }} resizeMode="cover" style={{ width: bannerWidth, height: bannerWidth * bannerRatio }} />
            </TouchableWithoutFeedback>
          ))}
      </ViewPager>
      <Row style={[Styles.bulletWrapper, CommonStyles.Layout.fullWidth]} justify="center">
        {data
          && data.map((d, i) => (
            <TouchableWithoutFeedback onPress={() => setSelectedIndex(i)} key={d.image}>
              <View style={[Styles.bullet, i < data.length - 1 ? CommonStyles.Space.MR0_5 : undefined, i === selectedIndex ? Styles.bulletActive : undefined]} />
            </TouchableWithoutFeedback>
          ))}
      </Row>
      <TouchableWithoutFeedback onPress={() => setSelectedIndex(getPrevIndex())}>
        <View style={[Styles.arrow, Styles.arrowLeft]}>
          <MetaIcon name="arrow" color={COLORS.white} size={16} />
        </View>
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback onPress={() => setSelectedIndex(getNextIndex())}>
        <View style={[Styles.arrow, Styles.arrowRight]}>
          <MetaIcon name="arrow" color={COLORS.white} size={16} />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

HeroSlider.defaultProps = {
  interval: 3000,
  autoplay: false,
};

export default HeroSlider;
