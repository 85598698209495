import React from 'react';
import { connect } from 'react-redux';
import { List, Text } from '@ui-kitten/components';
import DefaultLayout from '../../components/default-layout';
import { ReduxState } from '../../reduxers/reducers';
import { IScreenProps } from '../../interfaces/screen';
import Actions from '../../reduxers/actions';
import IMetadataState from '../../interfaces/states/metadata';
import Row from '../../components/grid/row';
import CommonStyles from '../../styles';
import { IFAQMeta } from '../../interfaces/models/metadata';
import FAQCard from '../../components/faq-card';
import Column from '../../components/grid/column';

interface MainProps extends IScreenProps {
  metadata: IMetadataState;
  getMetadata: (type: string) => void;
}

interface MainStates {}

class FAQScreen extends React.Component<MainProps, MainStates> {
  destinationFormRef: React.RefObject<any> = React.createRef();

  constructor(props: MainProps) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { getMetadata } = this.props;
    getMetadata('faq');
  };

  render = () => {
    const { metadata } = this.props;
    const faqMeta = metadata.list?.find((x) => x.type === 'faq');
    const parsedFAQMeta: Array<IFAQMeta> = faqMeta && faqMeta.value ? JSON.parse(faqMeta.value) : [];
    return (
      <DefaultLayout backButton footer {...this.props}>
        <Row style={[CommonStyles.Layout.fullWidth]}>
          <Column>
            <Row style={[CommonStyles.Space.P1]}>
              <Text style={[CommonStyles.Typography.TBold, CommonStyles.Typography.TS16]}>FAQ</Text>
            </Row>
            <List
              style={[CommonStyles.Background.transparent, CommonStyles.Space.PH1, CommonStyles.Layout.fullWidth]}
              data={parsedFAQMeta}
              renderItem={({ item }: { item: IFAQMeta }) => <FAQCard faq={item} />}
            />
          </Column>
        </Row>
      </DefaultLayout>
    );
  };
}

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  metadata: state.metadata,
});

const mapDispatchToProps = (dispatch: any) => ({
  getMetadata: (type: string) => dispatch(Actions.metadata.getByType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQScreen);
