import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import { AsyncStorage } from 'react-native';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';
import IUser, { IUserLoginRequest, IUserRegisterRequest, IUserUpdateRequest } from '../../interfaces/models/user';
import { IBaseResponse } from '../../interfaces/responses/base';
import STORAGE from '../../constants/storage';
import { navigate } from '../../utils/navigation';
import Actions from './index';

const handleSuccessAuth = async (token: string, dispatch: any) => {
  await AsyncStorage.setItem(STORAGE.token, token);
  const order = await AsyncStorage.getItem(STORAGE.order);
  const parsedOrder = order ? JSON.parse(order) : {};
  if (Object.keys(parsedOrder).length > 0) {
    dispatch(Actions.order.create(parsedOrder));
  } else {
    navigate('profile');
  }
};

const loginRequest = () => action(ActionTypes.user.USER_LOG_IN_REQUEST);
const loginFailure = (error: string) => action(ActionTypes.user.USER_LOG_IN_FAILURE, error);

const login = (data: IUserLoginRequest) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(
    dispatch,
    Api.user.login(data),
    loginRequest,
    (res: IBaseResponse<IUser>) => {
      if (res.success && res.data?.token) {
        handleSuccessAuth(res.data.token, dispatch);
      }
      return action(ActionTypes.user.USER_LOG_IN_SUCCESS, res.data);
    },
    loginFailure,
  );
};

const signupRequest = () => action(ActionTypes.user.USER_SIGN_UP_REQUEST);
const signupFailure = (error: string) => action(ActionTypes.user.USER_SIGN_UP_FAILURE, error);

const signup = (data: IUserRegisterRequest) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(
    dispatch,
    Api.user.signup(data),
    signupRequest,
    (res: IBaseResponse<IUser>) => {
      if (res.success && res.data?.token) {
        handleSuccessAuth(res.data.token, dispatch);
      }
      return action(ActionTypes.user.USER_SIGN_UP_SUCCESS, res.data);
    },
    signupFailure,
  );
};

const updateRequest = () => action(ActionTypes.user.USER_UPDATE_REQUEST);
const updateFailure = (error: string) => action(ActionTypes.user.USER_UPDATE_FAILURE, error);
const updateSuccess = (res: IBaseResponse<IUser>) => action(ActionTypes.user.USER_UPDATE_SUCCESS, res.data);

const update = (data: IUserUpdateRequest, callback?: () => void) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.user.update(data), updateRequest, updateSuccess, updateFailure, callback);
};

const currentRequest = () => action(ActionTypes.user.USER_CURRENT_REQUEST);
const currentFailure = (error: string) => action(ActionTypes.user.USER_CURRENT_FAILURE, error);
const currentSuccess = (res: IBaseResponse<IUser>) => action(ActionTypes.user.USER_CURRENT_SUCCESS, res.data);

const current = () => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.user.current(), currentRequest, currentSuccess, currentFailure);
};

const logout = () => (dispatch: Dispatch<AnyAction>) => {
  AsyncStorage.removeItem(STORAGE.token);
  dispatch(action(ActionTypes.user.USER_LOGOUT));
  setTimeout(() => {
    navigate('home');
  }, 0);
};

export default {
  login,
  signup,
  update,
  current,
  logout,
};
