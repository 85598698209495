import { Dimensions, StyleSheet } from 'react-native';
import { PADDING, SCREEN_MAX_WIDTH } from '../../constants';
import COLORS from '../../constants/color';

const { width } = Dimensions.get('window');
const hOffset = width > SCREEN_MAX_WIDTH ? (width - SCREEN_MAX_WIDTH) / 2 : 0;

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: COLORS.divider,
    position: 'absolute',
    width: '100%',
    maxWidth: SCREEN_MAX_WIDTH,
    top: 56,
    left: hOffset,
    right: hOffset,
    zIndex: 1001,
    padding: PADDING,
    borderRadius: 3,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.6,
    shadowRadius: 20,
    elevation: 5,
  },
  title: {
    fontWeight: '600',
    color: COLORS.blackOne,
    textAlign: 'center',
    marginHorizontal: PADDING,
  },
});

export default styles;
