import * as React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Divider, Text } from '@ui-kitten/components';
import CommonStyle from '../../styles';
import { IFAQMeta } from '../../interfaces/models/metadata';
import Row from '../grid/row';
import Column from '../grid/column';
import MetaIcon from '../icon';

interface MainProps {
  faq: IFAQMeta;
}

const Styles = StyleSheet.create({
  caretExpanded: {
    transform: [
      {
        rotate: '90deg',
      },
    ],
  },
});

const FAQCard = ({ faq }: MainProps) => {
  const [collapse, setCollapse] = React.useState(true);

  return (
    <View style={[CommonStyle.Space.MB1, CommonStyle.BorderRadius.BR3, CommonStyle.Background.divider]}>
      <TouchableOpacity onPress={() => setCollapse(!collapse)}>
        <Row style={[CommonStyle.Layout.fullWidth, CommonStyle.Space.P1]}>
          <Column>
            <Text style={[CommonStyle.Typography.TS16, CommonStyle.Typography.TBold]}>{faq.q}</Text>
          </Column>
          <MetaIcon name="arrow" size={16} style={[collapse ? {} : Styles.caretExpanded]} />
        </Row>
      </TouchableOpacity>
      {collapse ? <></> : <Divider style={[CommonStyle.Background.backdropBG, CommonStyle.Layout.fullWidth]} />}
      {collapse ? (
        <></>
      ) : (
        <Row style={[CommonStyle.Layout.fullWidth, CommonStyle.Space.PH1, CommonStyle.Space.PT1]}>
          <Column>
            {faq.a.map((item) => (
              <Row key={item} style={[CommonStyle.Layout.fullWidth, CommonStyle.Space.MB1]}>
                <Text style={[CommonStyle.Typography.TRegular]}>{item}</Text>
              </Row>
            ))}
          </Column>
        </Row>
      )}
    </View>
  );
};

export default FAQCard;
