/* style constant */
const SCREEN_MAX_WIDTH = 480;
const PADDING = 16;
/* end style constant */

const REGISTER_TAB = 'Register';
const LOGIN_TAB = 'Login';
const AUTH_TABS = [REGISTER_TAB, LOGIN_TAB];

const STATUS = {
  Pending: 'pending',
  Success: 'success',
  Delivered: 'delivered',
  Cancelled: 'cancelled',
  Expired: 'expired',
  Failed: 'failed',
  FailedPayment: 'failedPayment',
};

const TRANSLATED_STATUS: { [key: string]: string } = {};
TRANSLATED_STATUS[STATUS.Pending] = 'menunggu';
TRANSLATED_STATUS[STATUS.Success] = 'sukses';
TRANSLATED_STATUS[STATUS.Delivered] = 'terkirim';
TRANSLATED_STATUS[STATUS.Cancelled] = 'dibatalkan';
TRANSLATED_STATUS[STATUS.Expired] = 'kadaluarsa';
TRANSLATED_STATUS[STATUS.Failed] = 'gagal';
TRANSLATED_STATUS[STATUS.FailedPayment] = 'pembayaran gagal';

const ACTION = {
  Add: '0',
  Edit: '1',
};

const INQUIRY_TYPE = {
  InquireByProductID: 'productID',
  InquireByProductItemID: 'productItemID',
  InquireByProductTypeID: 'productTypeID',
};

const PHONE_LABELS = ['no hp', 'nomor hp', 'no telepon', 'nomor telepon', 'phonenumber'];

export { PADDING, SCREEN_MAX_WIDTH, AUTH_TABS, REGISTER_TAB, LOGIN_TAB, STATUS, TRANSLATED_STATUS, INQUIRY_TYPE, PHONE_LABELS, ACTION };
