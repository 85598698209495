import React from 'react';
import { TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { Button, Divider, List, Text } from '@ui-kitten/components';
import moment from 'moment';
import Column from '../../components/grid/column';
import Row from '../../components/grid/row';
import { IScreenProps } from '../../interfaces/screen';
import DefaultLayout from '../../components/default-layout';
import { ReduxState } from '../../reduxers/reducers';
import Actions from '../../reduxers/actions';
import CommonStyles from '../../styles';
import IOrderState from '../../interfaces/states/order';
import IOrder from '../../interfaces/models/order';
import { STATUS, TRANSLATED_STATUS } from '../../constants';
import MetaIcon from '../../components/icon';
import COLORS from '../../constants/color';
import { Capitalize } from '../../utils';

interface MainProps extends IScreenProps {
  order: IOrderState;
  getOrders: () => void;
}

const OrderListScreen = (props: MainProps): React.ReactElement => {
  const { navigation, getOrders, order } = props;

  React.useEffect(() => {
    getOrders();
  }, []);

  return (
    <DefaultLayout
      backButton={(
        <TouchableOpacity onPress={() => navigation.navigate('profile')}>
          <Row style={[CommonStyles.Space.P1, { backgroundColor: COLORS.divider }]}>
            <MetaIcon name="back" color={COLORS.infoBlue} size={18} />
            <Text style={[CommonStyles.Typography.TinfoBlue, CommonStyles.Typography.TS14, CommonStyles.Typography.TRegular, CommonStyles.Space.ML0_5]}>Akun saya</Text>
          </Row>
        </TouchableOpacity>
      )}
      footer
      {...props}
    >
      <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.PH1, CommonStyles.Space.PT1, CommonStyles.Background.divider]}>
        <Column>
          <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB1]}>
            <Text style={[CommonStyles.Typography.TS18, CommonStyles.Typography.TBold]}>Riwayat Transaksi</Text>
          </Row>
          <List
            style={[CommonStyles.Layout.fullWidth, CommonStyles.Background.transparent]}
            data={order.list}
            renderItem={({ item }: { item: IOrder }) => (
              <TouchableOpacity key={item.id} onPress={() => navigation.navigate('order', { receiptId: item.receiptID })}>
                <Column style={[CommonStyles.Space.PH1, CommonStyles.Space.PT1, CommonStyles.Space.MB1, CommonStyles.Background.white]}>
                  <Row style={[CommonStyles.Layout.fullWidth]}>
                    <Column>
                      <Text style={[CommonStyles.Typography.TS14, CommonStyles.Typography.TSemiBold]}>{moment.unix(item.createdAt).format('LLL')}</Text>
                    </Column>
                    <Column align="end">
                      <Text style={[CommonStyles.Typography.TS14, CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TblackTwo]}>Detail Transaksi</Text>
                    </Column>
                  </Row>
                  <Divider style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MV1]} />
                  {[
                    { label: 'Order ID', value: item.receiptID },
                    { label: 'Produk', value: item.productItem.name },
                    {
                      label: 'Status',
                      value: Capitalize(TRANSLATED_STATUS[item.paymentStatus]),
                      valueTextStyle: [[STATUS.Delivered, STATUS.Delivered].includes(item.paymentStatus) ? CommonStyles.Typography.Tgreen : CommonStyles.Typography.Tprice],
                    },
                  ].map((x) => (
                    <Row key={x.label} style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB1]}>
                      <Column>
                        <Text style={[CommonStyles.Typography.TS16, CommonStyles.Typography.TRegular, CommonStyles.Typography.TblackTw]}>{x.label}</Text>
                      </Column>
                      <Column align="end">
                        <Text style={[CommonStyles.Typography.TS16, CommonStyles.Typography.TRight, CommonStyles.Typography.TSemiBold, ...(x.valueTextStyle || [])]}>{x.value}</Text>
                      </Column>
                    </Row>
                  ))}
                </Column>
              </TouchableOpacity>
            )}
            ListEmptyComponent={(
              <Column style={[CommonStyles.Space.P1, CommonStyles.Background.white, CommonStyles.Space.MB1]}>
                <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MT3]}>
                  <Column align="center">
                    <MetaIcon name="empty-order" size={44} />
                  </Column>
                </Row>
                <Row style={[CommonStyles.Layout.fullWidth, CommonStyles.Space.MB3]}>
                  <Column align="center">
                    <Text style={[CommonStyles.Typography.TS16, CommonStyles.Typography.TSemiBolds]}>Tidak ada riwayat transaksi</Text>
                  </Column>
                </Row>
                <Row style={[CommonStyles.Layout.fullWidth]}>
                  <Button status="info" style={[CommonStyles.Layout.fullWidth]} onPress={() => navigation.navigate('home')}>
                    {() => <Text style={[CommonStyles.Typography.Twhite, CommonStyles.Typography.TS16, CommonStyles.Typography.TSemiBold]}>Kembali ke Homepage</Text>}
                  </Button>
                </Row>
              </Column>
            )}
          />
        </Column>
      </Row>
    </DefaultLayout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  order: state.order,
});

const mapDispatchToProps = (dispatch: any) => ({
  getOrders: () => dispatch(Actions.order.getList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderListScreen);
