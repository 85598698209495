import * as React from 'react';
import * as Font from 'expo-font';
import { AppLoading } from 'expo';
import { createIconSetFromFontello } from '@expo/vector-icons';
import { ViewStyle } from 'react-native';
import Colors from '../../constants/color';
import config from './config.json';

const customFonts = {
  meta: require('./fonts/meta.ttf'),
};

const Icon = createIconSetFromFontello(config, 'meta', 'meta.ttf');

interface MainProps {
  name: string;
  color?: string;
  size?: number;
  style?: ViewStyle | Array<ViewStyle>;
}

interface MainState {
  fontLoaded: boolean;
  mounted: boolean;
}
export default class MetaIcon extends React.Component<MainProps, MainState> {
  constructor(props: MainProps) {
    super(props);
    this.state = {
      fontLoaded: false,
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true }, () => {
      this.loadFontsAsync();
    });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  loadFontsAsync() {
    Font.loadAsync(customFonts).then(() => {
      this.setState({ fontLoaded: true });
    });
  }

  render() {
    const { name, color, size, style } = this.props;
    const { fontLoaded, mounted } = this.state;
    if (fontLoaded && mounted) {
      return <Icon name={name} size={size || 16} color={color || Colors.blackOne} style={style} />;
    }
    return <AppLoading />;
  }
}
