import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import { IBaseResponse } from '../../interfaces/responses/base';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';
import IProduct from '../../interfaces/models/product';

const getByIdRequest = () => action(ActionTypes.product.PRODUCT_GET_BY_ID_REQUEST);
const getByIdFailure = (error: string) => action(ActionTypes.product.PRODUCT_GET_BY_ID_FAILURE, error);
const getByIdSuccess = (res: IBaseResponse<IProduct>) => action(ActionTypes.product.PRODUCT_GET_BY_ID_SUCCESS, res.data);

const getById = (productId: number, callback?: () => void) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.product.getById(productId), getByIdRequest, getByIdSuccess, getByIdFailure, callback);
};

const getListRequest = () => action(ActionTypes.product.GET_ALL_PRODUCTS_REQUEST);
const getListFailure = (error: string) => action(ActionTypes.product.GET_ALL_PRODUCTS_FAILURE, error);
const getListSuccess = (res: IBaseResponse<IProduct>) => action(ActionTypes.product.GET_ALL_PRODUCTS_SUCCESS, res.data);

const getList = (callBack?: () => void) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.product.getList(), getListRequest, getListSuccess, getListFailure, callBack);
};

export default {
  getById,
  getList,
};
