import { StyleSheet } from 'react-native';
import COLORS from '../../constants/color';
import { PADDING } from '../../constants';

const styles = StyleSheet.create({
  bulletWrapper: {
    position: 'absolute',
    bottom: PADDING,
  },
  bullet: {
    width: PADDING,
    height: PADDING / 2,
    borderRadius: PADDING / 8,
    backgroundColor: COLORS.white,
    opacity: 0.4,
  },
  bulletActive: {
    opacity: 0.8,
  },
  arrow: {
    width: 32,
    height: 32,
    backgroundColor: COLORS.blackOne,
    opacity: 0.5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 'calc(50% - 16px)',
  },
  arrowLeft: {
    left: 0,
    transform: [
      {
        scaleX: -1,
      },
    ],
  },
  arrowRight: {
    right: 0,
  },
});

export default styles;
