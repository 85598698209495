import { StyleSheet } from 'react-native';
import COLORS from '../../constants/color';

const Styles = StyleSheet.create({
  indicator: {
    backgroundColor: COLORS.blackOne,
    height: 2,
  },
  tabBar: {
    height: 50,
  },
});

export default Styles;
