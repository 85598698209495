import * as React from 'react';
import { StyleSheet, View, Image, TouchableOpacity, Dimensions } from 'react-native';
import { StackNavigationOptions } from '@react-navigation/stack';
import { NavigationScreenProp, NavigationParams } from 'react-navigation';
import { NavigationState } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import headerLogo from '../../assets/images/metazone-logo.png';
import IUser from '../../interfaces/models/user';
import COLORS from '../../constants/color';
import { SCREEN_MAX_WIDTH, PADDING } from '../../constants';
import CommonStyles from '../../styles';
import MetaIcon from '../icon';
import Row from '../grid/row';
import Column from '../grid/column';

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: COLORS.theme,
    borderBottomWidth: 0,
    height: 56,
  },
  sideWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  logo: {
    width: 32,
    height: 32,
    marginVertical: 12,
    resizeMode: 'contain',
  },
});

const headerLeft = (navigation: NavigationScreenProp<NavigationState, NavigationParams>) => (
  <TouchableOpacity style={[styles.sideWrapper, CommonStyles.Space.ML1]} onPress={() => navigation.navigate('home')}>
    <Image source={headerLogo} style={styles.logo} />
  </TouchableOpacity>
);

const headerRight = (navigation: NavigationScreenProp<NavigationState, NavigationParams>, isLoggedIn: boolean, user?: IUser) => (
  <TouchableOpacity style={[styles.sideWrapper, CommonStyles.Space.MR1]} onPress={() => (isLoggedIn ? navigation.navigate('profile') : navigation.navigate('auth'))}>
    <Text style={[CommonStyles.Typography.Twhite, CommonStyles.Typography.TS14, CommonStyles.Typography.TSemiBold, CommonStyles.Space.MR0_5]}>
      {isLoggedIn ? ((user?.fullName?.length || 0) > 0 ? user?.fullName : 'Anonim') : 'Login'}
    </Text>
    <MetaIcon name={isLoggedIn ? 'profile' : 'login'} color={COLORS.white} size={18} />
  </TouchableOpacity>
);

const LogoHeader = (navigation: NavigationScreenProp<NavigationState, NavigationParams>, isLoggedIn: boolean, user?: IUser, withoutRightButton?: boolean) => {
  const { width } = Dimensions.get('window');
  const paddingHorizontal = width > SCREEN_MAX_WIDTH ? (width - SCREEN_MAX_WIDTH - PADDING) / 2 : 0;
  const stackNavigationoptions: StackNavigationOptions = {
    header: () => (
      <View>
        <Row style={[styles.wrapper, { paddingHorizontal }]} align="center">
          <Column>{headerLeft(navigation)}</Column>
          {!withoutRightButton && <Column align="end">{headerRight(navigation, isLoggedIn, user)}</Column>}
        </Row>
      </View>
    ),
  };
  return stackNavigationoptions;
};

export default LogoHeader;
