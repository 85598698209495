import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';
import IInquiryState from '../../interfaces/states/inquiry';

const initialState: IInquiryState = { ...BaseStateDefault };

const reducer: Reducer<any> = (state: IInquiryState = initialState, action): IInquiryState => {
  switch (action.type) {
    case ActionTypes.inquiry.INQUIRY_BY_PRODUCT_REQUEST:
    case ActionTypes.inquiry.INQUIRY_BY_PRODUCT_ITEM_REQUEST:
    case ActionTypes.inquiry.INQUIRY_BY_PRODUCT_TYPE_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined,
      };
    }
    case ActionTypes.inquiry.INQUIRY_BY_PRODUCT_FAILURE:
    case ActionTypes.inquiry.INQUIRY_BY_PRODUCT_ITEM_FAILURE:
    case ActionTypes.inquiry.INQUIRY_BY_PRODUCT_TYPE_FAILURE: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }
    case ActionTypes.inquiry.INQUIRY_BY_PRODUCT_SUCCESS:
    case ActionTypes.inquiry.INQUIRY_BY_PRODUCT_ITEM_SUCCESS: {
      return {
        ...state,
        requesting: false,
        data: action.payload,
      };
    }
    case ActionTypes.inquiry.INQUIRY_RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
