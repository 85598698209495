import * as React from 'react';
import { ScrollView, View, Dimensions, TouchableOpacity } from 'react-native';
import { List, Text, Divider } from '@ui-kitten/components';
import moment from 'moment';
import COLORS from '../../constants/color';
import CommonStyles from '../../styles';
import Row from '../grid/row';
import Column from '../grid/column';
import MetaIcon from '../icon';
import { IScreenProps } from '../../interfaces/screen';

interface IProps extends IScreenProps {
  children: React.ReactNode;
  footer?: boolean;
  backButton?: boolean | React.ReactNode;
}

const screenHeight = Dimensions.get('window').height;

const DefaultLayout = ({ children, footer, backButton, navigation }: IProps) => (
  <ScrollView style={[CommonStyles.Layout.wrapper]} showsVerticalScrollIndicator={false}>
    <View style={[{ minHeight: screenHeight - 490 }]}>
      {backButton ? (
        typeof backButton === 'boolean' ? (
          <TouchableOpacity onPress={() => navigation.navigate('home')}>
            <Row style={[CommonStyles.Space.P1, { backgroundColor: COLORS.divider }]}>
              <MetaIcon name="back" color={COLORS.infoBlue} size={18} />
              <Text style={[CommonStyles.Typography.TinfoBlue, CommonStyles.Typography.TS14, CommonStyles.Typography.TRegular, CommonStyles.Space.ML0_5]}>Halaman Utama</Text>
            </Row>
          </TouchableOpacity>
        ) : (
          <>{backButton}</>
        )
      ) : (
        <></>
      )}
      {children}
    </View>
    {footer && (
      <Row style={[CommonStyles.Background.theme, CommonStyles.Space.PH1, CommonStyles.Space.PV2]}>
        <Column>
          <Row>
            <Text style={[CommonStyles.Typography.Twhite, CommonStyles.Typography.TS16, CommonStyles.Typography.TSemiBoldItalic]}>Mengapa belanja di Metazone?</Text>
          </Row>
          <List
            style={[CommonStyles.Space.MT1_5, CommonStyles.Background.transparent, CommonStyles.Layout.fullWidth]}
            numColumns={2}
            data={[
              { icon: 'customer-service', label: 'Dukungan Transaksi' },
              { icon: 'instant-topup', label: 'Topup Saldo Instan' },
              { icon: 'safe', label: 'Transaksi Aman' },
              { icon: 'cheapest', label: 'Harga Terbaik' },
            ]}
            renderItem={({ item }) => (
              <Row key={item.label} style={[CommonStyles.Space.PV1]} align="center">
                {item.icon && <MetaIcon name={item.icon} color={COLORS.white} size={40} />}
                <Text
                  style={[
                    CommonStyles.Typography.Twhite,
                    CommonStyles.Typography.TS16,
                    CommonStyles.Typography.TLH24,
                    CommonStyles.Typography.TRegular,
                    CommonStyles.Space.MH1,
                    CommonStyles.Layout.row,
                    CommonStyles.Layout.alignCenter,
                  ]}
                >
                  {item.label}
                </Text>
              </Row>
            )}
          />
          <List
            style={[CommonStyles.Space.MT1_5, CommonStyles.Background.transparent, CommonStyles.Layout.fullWidth]}
            numColumns={2}
            data={[{ link: 'home', label: 'Topup saldo' }, { link: 'profile', label: 'Akun' }, { link: 'faq', label: 'Pertanyaan umum' }, {}]}
            renderItem={({ item }) => (
              <TouchableOpacity onPress={() => navigation.navigate(item.link)} style={[{ flex: 1 / 2 }]}>
                <Row key={item.label} style={[CommonStyles.Space.PV1]} justify="space">
                  <Text style={[CommonStyles.Typography.Twhite, CommonStyles.Typography.TS14, CommonStyles.Typography.TRegular]}>{item.label}</Text>
                  {item.label && <MetaIcon name="arrow" color={COLORS.white} size={16} style={[CommonStyles.Space.MR1]} />}
                </Row>
              </TouchableOpacity>
            )}
          />
          <Divider style={[CommonStyles.Space.MV1, CommonStyles.Layout.fullWidth]} />
          <Row justify="center" style={[CommonStyles.Layout.fullWidth]}>
            <Column align="center">
              <Text style={[CommonStyles.Typography.Twhite, CommonStyles.Typography.TS14, CommonStyles.Typography.TRegular, CommonStyles.Typography.TCenter]}>
                Seluruh Hak Cipta Metazone dilindungi undang-undang. 2020
                {moment().year() > 2020 ? ` - ${moment().year()}` : ''}
                .
              </Text>
            </Column>
          </Row>
        </Column>
      </Row>
    )}
  </ScrollView>
);

DefaultLayout.defaultProps = {
  footer: false,
  backButton: false,
};

export default DefaultLayout;
