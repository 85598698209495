import { Reducer } from 'redux';
import IProductState from '../../interfaces/states/product';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';

const initialState: IProductState = { ...BaseStateDefault };

const reducer: Reducer<any> = (state: IProductState = initialState, action): IProductState => {
  switch (action.type) {
    case ActionTypes.product.GET_ALL_PRODUCTS_REQUEST:
    case ActionTypes.product.PRODUCT_GET_BY_ID_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined,
      };
    }
    case ActionTypes.product.GET_ALL_PRODUCTS_FAILURE:
    case ActionTypes.product.PRODUCT_GET_BY_ID_FAILURE: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }
    case ActionTypes.product.PRODUCT_GET_BY_ID_SUCCESS: {
      return {
        ...state,
        requesting: false,
        data: action.payload,
      };
    }

    case ActionTypes.product.GET_ALL_PRODUCTS_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
