import Base from './base';
import { IPaymentMethodRequest } from '../../interfaces/models/payment-method';

export default {
  getList: async (data?: IPaymentMethodRequest): Promise<any> => (await Base()).get(
    `/payments/methods?${data && data.productId ? `&productID=${data.productId}` : ''}${data && data.productItemId ? `&productItemID=${data.productItemId}` : ''}${
      data && data.customerID ? `&customerID=${data.customerID}` : ''
    }`,
  ),
};
