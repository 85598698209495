import * as Sentry from '@sentry/browser';
import App from './src/app';
import ENV from './src/utils/environment';

if (ENV.ENABLE_SENTRY) {
  Sentry.init({
    dsn: 'https://2172caf87e31484eadfc6081f833e691@o275789.ingest.sentry.io/5417361',
    debug: true,
  });
}

export default App;
