import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';
import IProductDestinationState from '../../interfaces/states/product-destination';

const initialState: IProductDestinationState = { ...BaseStateDefault };

const reducer: Reducer<any> = (state: IProductDestinationState = initialState, action): IProductDestinationState => {
  switch (action.type) {
    case ActionTypes.productDestination.PRODUCT_DESTINATION_GET_LIST_REQUEST:
    case ActionTypes.productDestination.ADD_PRODUCT_DESTINATION_REQUEST:
    case ActionTypes.productDestination.REMOVE_PRODUCT_DESTINATION_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined,
      };
    }
    case ActionTypes.productDestination.PRODUCT_DESTINATION_GET_LIST_FAILURE:
    case ActionTypes.productDestination.ADD_PRODUCT_DESTINATION_FAILURE:
    case ActionTypes.productDestination.REMOVE_PRODUCT_DESTINATION_FAILURE: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }
    case ActionTypes.productDestination.PRODUCT_DESTINATION_GET_LIST_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload,
      };
    }
    case ActionTypes.productDestination.REMOVE_PRODUCT_DESTINATION_SUCCESS:
    case ActionTypes.productDestination.ADD_PRODUCT_DESTINATION_SUCCESS: {
      const List = state.list?.filter((x) => x.id !== action.payload.id);
      if (List && state.list && List.length === state.list.length) {
        List.push(action.payload);
      }
      return {
        ...state,
        requesting: false,
        list: List,
      };
    }
    case ActionTypes.user.USER_LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
