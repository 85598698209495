import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import { IBaseResponse } from '../../interfaces/responses/base';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';
import { IInquiryProduct } from '../../interfaces/models/inquiry';
import IProductItem from '../../interfaces/models/product-item';
import * as Snackbar from '../../components/snackbar';

const getByProductRequest = () => action(ActionTypes.inquiry.INQUIRY_BY_PRODUCT_REQUEST);
const getByProductFailure = (error: string) => action(ActionTypes.inquiry.INQUIRY_BY_PRODUCT_FAILURE, error);

const getByProduct = (productId: number, customerId: string) => async (dispatch: Dispatch<AnyAction>) => {
  await Base.CallAction(
    dispatch,
    Api.inquiry.getByProductId(productId, customerId),
    getByProductRequest,
    (res: IBaseResponse<IInquiryProduct>) => {
      if (res.data?.invalidMessage) {
        Snackbar.show(res.data.invalidMessage, Snackbar.MEDIUM, Snackbar.WARNING);
        return action(ActionTypes.inquiry.INQUIRY_BY_PRODUCT_FAILURE, res.data?.invalidMessage);
      }
      return action(ActionTypes.inquiry.INQUIRY_BY_PRODUCT_SUCCESS, res.data);
    },
    getByProductFailure,
  );
};

const getByProductItemRequest = () => action(ActionTypes.inquiry.INQUIRY_BY_PRODUCT_ITEM_REQUEST);
const getByProductItemFailure = (error: string) => action(ActionTypes.inquiry.INQUIRY_BY_PRODUCT_ITEM_FAILURE, error);
const getByProductItemSuccess = (res: IBaseResponse<IInquiryProduct>) => action(ActionTypes.inquiry.INQUIRY_BY_PRODUCT_ITEM_SUCCESS, res.data);

const getByProductItem = (productId: number, productItemId: number, destination: string) => async (dispatch: Dispatch<AnyAction>) => {
  await Base.CallAction(dispatch, Api.inquiry.getByProductItemId(productId, productItemId, destination), getByProductItemRequest, getByProductItemSuccess, getByProductItemFailure);
};

const getByProductTypeRequest = () => action(ActionTypes.inquiry.INQUIRY_BY_PRODUCT_TYPE_REQUEST);
const getByProductTypeFailure = (error: string) => action(ActionTypes.inquiry.INQUIRY_BY_PRODUCT_TYPE_FAILURE, error);
const getByProductTypeSuccess = (res: IBaseResponse<Array<IProductItem>>) => action(ActionTypes.inquiry.INQUIRY_BY_PRODUCT_TYPE_SUCCESS, res.data);

const getByProductType = (producTypeId: number, destination: string) => async (dispatch: Dispatch<AnyAction>) => {
  await Base.CallAction(dispatch, Api.inquiry.getByProductTypeId(producTypeId, destination), getByProductTypeRequest, getByProductTypeSuccess, getByProductTypeFailure);
};

const reset = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(action(ActionTypes.inquiry.INQUIRY_RESET));
};

export default {
  getByProduct,
  getByProductItem,
  getByProductType,
  reset,
};
