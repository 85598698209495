import { Reducer } from 'redux';
import IOrderState from '../../interfaces/states/order';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';

const initialState: IOrderState = { ...BaseStateDefault };

const reducer: Reducer<any> = (state: IOrderState = initialState, action): IOrderState => {
  switch (action.type) {
    case ActionTypes.order.ORDER_CREATE_REQUEST:
    case ActionTypes.order.ORDER_CANCEL_REQUEST:
    case ActionTypes.order.ORDER_GET_LIST_REQUEST:
    case ActionTypes.order.ORDER_GET_BY_ID_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined,
      };
    }
    case ActionTypes.order.ORDER_CREATE_FAILURE:
    case ActionTypes.order.ORDER_CANCEL_FAILURE:
    case ActionTypes.order.ORDER_GET_LIST_FAILURE:
    case ActionTypes.order.ORDER_GET_BY_ID_FAILURE: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }
    case ActionTypes.order.ORDER_CREATE_SUCCESS:
    case ActionTypes.order.ORDER_CANCEL_SUCCESS:
    case ActionTypes.order.ORDER_GET_BY_ID_SUCCESS: {
      return {
        ...state,
        requesting: false,
        data: action.payload,
      };
    }
    case ActionTypes.order.ORDER_GET_LIST_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
