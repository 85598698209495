import metadata from './metadata';
import inquiry from './inquiry';
import order from './order';
import paymentMethod from './payment-method';
import point from './point';
import product from './product';
import productDestination from './product-destination';
import productItem from './product-item';
import productType from './product-type';
import user from './user';

export default {
  metadata,
  inquiry,
  order,
  paymentMethod,
  point,
  product,
  productDestination,
  productItem,
  productType,
  user,
};
