import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import { IPoint } from '../../interfaces/models/point';
import { IBaseResponse } from '../../interfaces/responses/base';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';

const getListRequest = () => action(ActionTypes.point.POINT_GET_LIST_REQUEST);
const getListFailure = (error: string) => action(ActionTypes.point.POINT_GET_LIST_FAILURE, error);
const getListSuccess = (res: IBaseResponse<Array<IPoint>>) => action(ActionTypes.point.POINT_GET_LIST_SUCCESS, res.data);

const getList = () => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.point.getList(), getListRequest, getListSuccess, getListFailure);
};

export default {
  getList,
};
