const USER_LOG_IN_REQUEST = 'USER_LOG_IN_REQUEST';
const USER_LOG_IN_SUCCESS = 'USER_LOG_IN_SUCCESS';
const USER_LOG_IN_FAILURE = 'USER_LOG_IN_FAILURE';

const USER_SIGN_UP_REQUEST = 'USER_SIGN_UP_REQUEST';
const USER_SIGN_UP_SUCCESS = 'USER_SIGN_UP_SUCCESS';
const USER_SIGN_UP_FAILURE = 'USER_SIGN_UP_FAILURE';

const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

const USER_CURRENT_REQUEST = 'USER_CURRENT_REQUEST';
const USER_CURRENT_SUCCESS = 'USER_CURRENT_SUCCESS';
const USER_CURRENT_FAILURE = 'USER_CURRENT_FAILURE';

const USER_LOGOUT = 'USER_LOGOUT';

export default {
  USER_LOG_IN_REQUEST,
  USER_LOG_IN_SUCCESS,
  USER_LOG_IN_FAILURE,

  USER_SIGN_UP_REQUEST,
  USER_SIGN_UP_SUCCESS,
  USER_SIGN_UP_FAILURE,

  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,

  USER_CURRENT_REQUEST,
  USER_CURRENT_SUCCESS,
  USER_CURRENT_FAILURE,

  USER_LOGOUT,
};
