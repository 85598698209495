import { Dispatch, AnyAction } from 'redux';
import { IBaseResponse } from '../../interfaces/responses/base';
import IAPIResponse from '../../interfaces/responses/api';
import * as Snackbar from '../../components/snackbar';

const handleError = (error: string, onFailed: (message: string) => any, dispatch: Dispatch<AnyAction>) => {
  Snackbar.show(error, Snackbar.MEDIUM, Snackbar.WARNING);
  dispatch(onFailed(error));
};

const ErrorResponse = (error: any, dispatch: Dispatch<AnyAction>, onFailed: (message: string) => any) => {
  if (error.response) {
    handleError(error.response.data.errors.description, onFailed, dispatch);
  } else {
    handleError(error.message, onFailed, dispatch);
  }
};

const CallAction = (
  dispatch: Dispatch<AnyAction>,
  api: Promise<IAPIResponse>,
  onRequested: () => any,
  onSuccess: (data: IBaseResponse<any>) => any,
  onFailed: (message: string) => any,
  callback?: () => void,
) => {
  dispatch(onRequested());
  return api
    .then((response) => {
      if (response.data.success) {
        dispatch(onSuccess(response.data));
      }
    })
    .catch((error: any) => {
      ErrorResponse(error, dispatch, onFailed);
    })
    .finally(() => {
      if (callback) callback();
    });
};

export default {
  CallAction,
};
