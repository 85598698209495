const PRODUCT_GET_BY_ID_REQUEST = 'PRODUCT_GET_BY_ID_REQUEST';
const PRODUCT_GET_BY_ID_SUCCESS = 'PRODUCT_GET_BY_ID_SUCCESS';
const PRODUCT_GET_BY_ID_FAILURE = 'PRODUCT_GET_BY_ID_FAILURE';

const GET_ALL_PRODUCTS_REQUEST = 'GET_ALL_PRODUCTS_REQUEST';
const GET_ALL_PRODUCTS_FAILURE = 'GET_ALL_PRODUCTS_FAILURE';
const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';

export default {
  PRODUCT_GET_BY_ID_REQUEST,
  PRODUCT_GET_BY_ID_FAILURE,
  PRODUCT_GET_BY_ID_SUCCESS,

  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_FAILURE,
  GET_ALL_PRODUCTS_SUCCESS,
};
