import { StyleSheet } from 'react-native';

const Styles = StyleSheet.create({
  productItem: {
    height: 104,
    width: '100%',
    borderRadius: 3,
  },
});

export default Styles;
