import * as React from 'react';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import IUser from '../interfaces/models/user';

const Stack = createStackNavigator();

interface MainProps {
  navigations: any;
  user?: IUser;
  isLoggedIn: boolean;
  option?: StackNavigationOptions;
}

const StackNavigation = ({ navigations, user, isLoggedIn, option }: MainProps) => (
  <Stack.Navigator screenOptions={() => ({ ...option })} initialRouteName={navigations[0].name}>
    {navigations.map((r: any) => (
      <Stack.Screen
        key={r.name}
        name={r.name}
        component={r.component}
        options={({ navigation }) => ({
          title: 'Metazone',
          ...r.headerOptions(navigation, isLoggedIn, user, r.withoutRightButton),
        })}
      />
    ))}
  </Stack.Navigator>
);

StackNavigation.defaultProps = {
  option: undefined,
  user: undefined,
};

export default StackNavigation;
