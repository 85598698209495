import * as React from 'react';
import { View, ViewStyle } from 'react-native';
import CommonStyle from '../../styles';
import { Capitalize } from '../../utils';

interface IProps {
  children: React.ReactNode;
  justify?: 'start' | 'center' | 'end' | 'space';
  align?: 'start' | 'center' | 'end' | 'space';
  style?: ViewStyle | Array<ViewStyle>;
}
const Column = ({ children, justify, align, style }: IProps) => {
  const styles = Array.isArray(style) ? style : [style];
  return (
    <View
      style={[
        CommonStyle.Layout.column,
        CommonStyle.Layout.full,
        CommonStyle.Layout[`justify${Capitalize(justify)}`],
        CommonStyle.Layout[`align${Capitalize(align)}`],
        ...styles,
      ]}
    >
      {children}
    </View>
  );
};

Column.defaultProps = {
  justify: 'start',
  align: 'start',
  style: {},
};

export default Column;
