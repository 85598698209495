import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import { IBaseResponse } from '../../interfaces/responses/base';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';
import { IMetadata } from '../../interfaces/models/metadata';

const getListRequest = () => action(ActionTypes.metadata.METADATA_GET_LIST_REQUEST);
const getListFailure = (error: string) => action(ActionTypes.metadata.METADATA_GET_LIST_FAILURE, error);
const getListSuccess = (res: IBaseResponse<Array<IMetadata>>) => action(ActionTypes.metadata.METADATA_GET_LIST_SUCCESS, res.data);

const getList = () => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.metadata.getList(), getListRequest, getListSuccess, getListFailure);
};

const getByTypeRequest = () => action(ActionTypes.metadata.METADATA_GET_BY_TYPE_REQUEST);
const getByTypeFailure = (error: string) => action(ActionTypes.metadata.METADATA_GET_BY_TYPE_FAILURE, error);
const getByTypeSuccess = (res: IBaseResponse<IMetadata>) => action(ActionTypes.metadata.METADATA_GET_BY_TYPE_SUCCESS, res.data);

const getByType = (receiptId: string) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.metadata.getByType(receiptId), getByTypeRequest, getByTypeSuccess, getByTypeFailure);
};

export default {
  getList,
  getByType,
};
