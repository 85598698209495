import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';
import IMetadataState from '../../interfaces/states/metadata';

const initialState: IMetadataState = { ...BaseStateDefault };

const reducer: Reducer<any> = (state: IMetadataState = initialState, action): IMetadataState => {
  switch (action.type) {
    case ActionTypes.metadata.METADATA_GET_LIST_REQUEST:
    case ActionTypes.metadata.METADATA_GET_BY_TYPE_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined,
      };
    }
    case ActionTypes.metadata.METADATA_GET_LIST_FAILURE:
    case ActionTypes.metadata.METADATA_GET_BY_TYPE_FAILURE: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }
    case ActionTypes.metadata.METADATA_GET_BY_TYPE_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: [...(state.list || []), ...[action.payload]],
      };
    }
    case ActionTypes.metadata.METADATA_GET_LIST_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
