import { StyleSheet } from 'react-native';

const createBorderRadiusVariant = (radius: number) => {
  const variant: { [k: string]: any } = {};
  variant[`BRTL${radius}`] = { borderTopLeftRadius: radius };
  variant[`BRTR${radius}`] = { borderTopRightRadius: radius };
  variant[`BRBR${radius}`] = { borderBottomRightRadius: radius };
  variant[`BRBL${radius}`] = { borderBottomLeftRadius: radius };
  variant[`BRT${radius}`] = { borderTopLeftRadius: radius, borderTopRightRadius: radius };
  variant[`BRB${radius}`] = { borderBottomLeftRadius: radius, borderBottomRightRadius: radius };
  variant[`BRL${radius}`] = { borderTopLeftRadius: radius, borderBottomLeftRadius: radius };
  variant[`BRR${radius}`] = { borderTopRightRadius: radius, borderBottomRightRadius: radius };
  variant[`BR${radius}`] = { borderRadius: radius };

  return variant;
};

const VarBorderRadius = StyleSheet.create({
  ...createBorderRadiusVariant(0),
  ...createBorderRadiusVariant(3),
});

export default VarBorderRadius;
