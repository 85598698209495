import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import IProductDestination, { IRequestParamsDestination } from '../../interfaces/models/product-destination';
import { IBaseResponse } from '../../interfaces/responses/base';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';

const getListRequest = () => action(ActionTypes.productDestination.PRODUCT_DESTINATION_GET_LIST_REQUEST);
const getListFailure = (error: string) => action(ActionTypes.productDestination.PRODUCT_DESTINATION_GET_LIST_FAILURE, error);
const getListSuccess = (res: IBaseResponse<Array<IProductDestination>>) => action(ActionTypes.productDestination.PRODUCT_DESTINATION_GET_LIST_SUCCESS, res.data);
const getList = (productId?: number) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.productDestination.getList(productId), getListRequest, getListSuccess, getListFailure);
};

const addRequest = () => action(ActionTypes.productDestination.ADD_PRODUCT_DESTINATION_REQUEST);
const addFailure = (error: string) => action(ActionTypes.productDestination.ADD_PRODUCT_DESTINATION_FAILURE, error);
const addSuccess = (res: IBaseResponse<Array<IRequestParamsDestination>>) => action(ActionTypes.productDestination.ADD_PRODUCT_DESTINATION_SUCCESS, res.data);
const add = (data: IRequestParamsDestination, callback?: () => void) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.productDestination.add(data), addRequest, addSuccess, addFailure, callback);
};

const removeRequest = () => action(ActionTypes.productDestination.REMOVE_PRODUCT_DESTINATION_REQUEST);
const removeFailure = (error: string) => action(ActionTypes.productDestination.REMOVE_PRODUCT_DESTINATION_FAILURE, error);
const removeSuccess = (res: IBaseResponse<Array<IRequestParamsDestination>>) => action(ActionTypes.productDestination.REMOVE_PRODUCT_DESTINATION_SUCCESS, res.data);
const remove = (destId: string, callback?: () => void) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.productDestination.remove(destId), removeRequest, removeSuccess, removeFailure, callback);
};

export default {
  getList,
  add,
  remove,
};
