import { Reducer } from 'redux';
import IUserState from '../../interfaces/states/user';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';

const initialState: IUserState = { ...BaseStateDefault };

const reducer: Reducer<any> = (state: IUserState = initialState, action): IUserState => {
  switch (action.type) {
    case ActionTypes.user.USER_LOG_IN_REQUEST:
    case ActionTypes.user.USER_SIGN_UP_REQUEST:
    case ActionTypes.user.USER_UPDATE_REQUEST:
    case ActionTypes.user.USER_CURRENT_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined,
      };
    }
    case ActionTypes.user.USER_LOG_IN_FAILURE:
    case ActionTypes.user.USER_SIGN_UP_FAILURE:
    case ActionTypes.user.USER_UPDATE_FAILURE:
    case ActionTypes.user.USER_CURRENT_FAILURE: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }
    case ActionTypes.user.USER_LOG_IN_SUCCESS:
    case ActionTypes.user.USER_SIGN_UP_SUCCESS:
    case ActionTypes.user.USER_UPDATE_SUCCESS:
    case ActionTypes.user.USER_CURRENT_SUCCESS: {
      return {
        ...state,
        requesting: false,
        data: action.payload,
      };
    }
    case ActionTypes.user.USER_LOGOUT: {
      delete state.data;
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
