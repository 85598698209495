const METADATA_GET_LIST_REQUEST = 'METADATA_GET_LIST_REQUEST';
const METADATA_GET_LIST_SUCCESS = 'METADATA_GET_LIST_SUCCESS';
const METADATA_GET_LIST_FAILURE = 'METADATA_GET_LIST_FAILURE';

const METADATA_GET_BY_TYPE_REQUEST = 'METADATA_GET_BY_TYPE_REQUEST';
const METADATA_GET_BY_TYPE_SUCCESS = 'METADATA_GET_BY_TYPE_SUCCESS';
const METADATA_GET_BY_TYPE_FAILURE = 'METADATA_GET_BY_TYPE_FAILURE';

export default {
  METADATA_GET_LIST_REQUEST,
  METADATA_GET_LIST_FAILURE,
  METADATA_GET_LIST_SUCCESS,

  METADATA_GET_BY_TYPE_REQUEST,
  METADATA_GET_BY_TYPE_FAILURE,
  METADATA_GET_BY_TYPE_SUCCESS,
};
