import { StyleSheet } from 'react-native';
import COLORS from '../constants/color';

const createBackgroundColorVariant = (colors: { [k: string]: string }) => {
  const variant: { [k: string]: any } = {};
  Object.keys(colors).forEach((c) => {
    variant[`${c}`] = { backgroundColor: colors[c] };
  });
  variant.transparent = { backgroundColor: 'transparent' };
  return variant;
};

const VarBackground = StyleSheet.create({
  ...createBackgroundColorVariant(COLORS),
});

export default VarBackground;
