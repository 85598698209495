import React from 'react';
import { Image, SectionList, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { List, Text } from '@ui-kitten/components';
import Column from '../../components/grid/column';
import IProduct from '../../interfaces/models/product';
import Row from '../../components/grid/row';
import { IScreenProps } from '../../interfaces/screen';
import IProductTypeState from '../../interfaces/states/product-type';
import DefaultLayout from '../../components/default-layout';
import { ReduxState } from '../../reduxers/reducers';
import Actions from '../../reduxers/actions';
import CommonStyles from '../../styles';
import Styles from './index.style';
import HeroSlider from '../../components/hero-slider';
import IProductType from '../../interfaces/models/product-type';
import IMetadataState from '../../interfaces/states/metadata';
import { IBannerMeta } from '../../interfaces/models/metadata';

interface MainProps extends IScreenProps {
  productType: IProductTypeState;
  getProductTypes: () => Promise<any>;

  metadata: IMetadataState;
  getMetadata: (type: string) => void;
}

const HomeScreen = (props: MainProps): React.ReactElement => {
  const { getProductTypes, productType, navigation, getMetadata, metadata } = props;

  const bannerMeta = metadata.list?.find((x) => x.type === 'banner');
  const parsedbannerMeta: IBannerMeta = bannerMeta && bannerMeta.value ? JSON.parse(bannerMeta.value) : {};

  React.useEffect(() => {
    getProductTypes();
    getMetadata('banner');
  }, []);

  return (
    <DefaultLayout footer {...props}>
      <HeroSlider data={parsedbannerMeta.data} autoplay={parsedbannerMeta.autoplay} interval={parsedbannerMeta.interval} />
      <SectionList
        style={[CommonStyles.Layout.fullWidth]}
        refreshing={productType.requesting}
        sections={(productType.list || [])
          .filter((pk: IProductType) => pk.products && pk.products.length > 0)
          .map((pk: IProductType) => ({ id: pk.id, title: pk.name, data: [{ id: pk.id, list: pk.products }] }))}
        keyExtractor={(p, index) => `${p.id}-${index}`}
        renderSectionHeader={({ section: { id, title } }) => (
          <Row key={id} style={[CommonStyles.Space.M1]}>
            <Text style={[CommonStyles.Typography.TS18, CommonStyles.Typography.TBold]}>{title}</Text>
          </Row>
        )}
        renderItem={({ item: sectionData }) => (
          <List
            style={[CommonStyles.Space.MH0_5, CommonStyles.Background.transparent]}
            numColumns={3}
            data={sectionData.list}
            renderItem={({ item: product }: { item: IProduct }) => (
              <TouchableOpacity style={[{ flex: 1 / 3 }]} onPress={() => navigation.navigate('payment', { productId: product.id })}>
                <Column style={[CommonStyles.Space.P0_5]} align="center">
                  <Image source={{ uri: product?.image }} style={[Styles.productItem, CommonStyles.Background.blackTwo]} />
                  <Text style={[CommonStyles.Typography.TS14, CommonStyles.Typography.TCenter, CommonStyles.Typography.TSemiBold, CommonStyles.Space.MV0_5]} numberOfLines={1}>
                    {product.name}
                  </Text>
                </Column>
              </TouchableOpacity>
            )}
          />
        )}
      />
    </DefaultLayout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  productType: state.productType,
  metadata: state.metadata,
});

const mapDispatchToProps = (dispatch: any) => ({
  getProductTypes: () => dispatch(Actions.productType.getList()),
  getMetadata: (type: string) => dispatch(Actions.metadata.getByType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
