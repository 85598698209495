import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import IProductItem from '../../interfaces/models/product-item';
import { IBaseResponse } from '../../interfaces/responses/base';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';

const getListRequest = () => action(ActionTypes.productItem.PRODUCT_ITEM_GET_LIST_REQUEST);
const getListFailure = (error: string) => action(ActionTypes.productItem.PRODUCT_ITEM_GET_LIST_FAILURE, error);
const getListSuccess = (res: IBaseResponse<Array<IProductItem>>) => action(ActionTypes.productItem.PRODUCT_ITEM_GET_LIST_SUCCESS, res.data);

const getList = (productId: number, productItemId?: number, callback?: () => void) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.productItem.getList(productId, productItemId), getListRequest, getListSuccess, getListFailure, callback);
};

export default {
  getList,
};
