import { StyleSheet, Dimensions } from 'react-native';
import { SCREEN_MAX_WIDTH } from '../../constants';
import COLORS from '../../constants/color';

const { width } = Dimensions.get('window');

const Styles = StyleSheet.create({
  modal: {
    backgroundColor: COLORS.white,
    width: width < SCREEN_MAX_WIDTH ? width : SCREEN_MAX_WIDTH,
    bottom: 0,
    top: 'unset',
  },
  modalBackdrop: {
    maxWidth: SCREEN_MAX_WIDTH,
    marginHorizontal: 'auto',
    backgroundColor: COLORS.backdropBG,
  },
});

export default Styles;
