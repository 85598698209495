import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TabView, Tab, Text } from '@ui-kitten/components';
import { ReduxState } from '../../reduxers/reducers';
import DefaultLayout from '../../components/default-layout';
import { IScreenProps } from '../../interfaces/screen';
import Styles from './index.style';
import CommonStyles from '../../styles';
import { AUTH_TABS, REGISTER_TAB, LOGIN_TAB } from '../../constants';
import DefaultForm, { IDefaultFormProps, IDefaultFormValue } from '../../components/form';
import Actions from '../../reduxers/actions';
import { IUserLoginRequest, IUserRegisterRequest } from '../../interfaces/models/user';

interface MainProps extends IScreenProps {
  register: (data: IUserRegisterRequest) => Promise<any>;
  login: (data: IUserLoginRequest) => Promise<any>;
}

const AuthScreen = (props: MainProps): React.ReactElement => {
  const onLogin = (data?: IDefaultFormValue) => {
    if (data) {
      const { login } = props;
      login({ email: data.email.toString(), password: data.password.toString() });
    }
  };

  const onRegister = (data?: IDefaultFormValue) => {
    if (data) {
      const { register } = props;
      register({ email: data.email.toString(), password: data.password.toString(), confirmPassword: data.kpassword.toString() });
    }
  };

  const authFields: Array<IDefaultFormProps> = [
    {
      fields: [
        { label: 'Email', name: 'email', placeholder: 'youremail@domain.com', type: 'email', required: true },
        { label: 'Password', name: 'password', placeholder: 'Minimal 8 karakter', type: 'password', required: true, min: 8 },
        { label: 'Konfirmasi Password', name: 'kpassword', placeholder: 'Minimal 8 karakter', type: 'password', required: true },
      ],
      button: {
        text: REGISTER_TAB,
        action: onRegister,
      },
      alert: 'Dapatkan DISCOUNT 20% untuk transaksi pertama',
    },
    {
      fields: [
        { label: 'Email', name: 'email', placeholder: 'youremail@domain.com', type: 'email', required: true },
        { label: 'Password', name: 'password', type: 'password', placeholder: '********', required: true },
      ],
      button: {
        text: LOGIN_TAB,
        action: onLogin,
      },
      alert: 'Kumpulkan poin dan pakai untuk transaksi',
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelectTab = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <DefaultLayout backButton {...props}>
      <TabView selectedIndex={selectedIndex} onSelect={(index) => onSelectTab(index)} indicatorStyle={[Styles.indicator]} tabBarStyle={[Styles.tabBar]}>
        {AUTH_TABS.map((t, i) => (
          <Tab
            key={t}
            title={(tabProps) => (
              <Text
                {...tabProps}
                style={[CommonStyles.Typography.TblackTwo, CommonStyles.Typography.TSemiBold, CommonStyles.Typography.TS16, i === selectedIndex ? CommonStyles.Typography.TblackOne : undefined]}
              >
                {t}
              </Text>
            )}
          />
        ))}
      </TabView>
      <DefaultForm {...authFields[selectedIndex]} />
    </DefaultLayout>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  ...state,
});

const mapDispatchToProps = (dispatch: any) => ({
  register: (data: IUserRegisterRequest) => dispatch(Actions.user.signup(data)),
  login: (data: IUserLoginRequest) => dispatch(Actions.user.login(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthScreen);
