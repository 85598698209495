export const FormatNumber = (input: number) => input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const ConcatText = (limit: number, string: string) => {
  if (string.length > limit) {
    return `${string.substring(0, limit - 4)}...`;
  }
  return string;
};

export const IsValidEmail = (email: string) => {
  const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!email) {
    return false;
  }

  if (email.length > 256) {
    return false;
  }

  if (!tester.test(email)) {
    return false;
  }

  // Further checking of some things regex can't handle
  const [account, address] = email.split('@');
  if (account.length > 64) {
    return false;
  }

  const domainParts = address.split('.');
  if (domainParts.some((part) => part.length > 63)) {
    return false;
  }

  return true;
};

export const Capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const IsOnlyNumber = (text: string) => {
  const re = /^[0-9]*$/;
  return re.test(text);
};
