import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import IProductType from '../../interfaces/models/product-type';
import { IBaseResponse } from '../../interfaces/responses/base';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';

const getListRequest = () => action(ActionTypes.productType.PRODUCT_TYPE_GET_LIST_REQUEST);
const getListFailure = (error: string) => action(ActionTypes.productType.PRODUCT_TYPE_GET_LIST_FAILURE, error);
const getListSuccess = (data: IBaseResponse<Array<IProductType>>) => action(ActionTypes.productType.PRODUCT_TYPE_GET_LIST_SUCCESS, data.data);

const getList = (limit?: number, page?: number) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.productType.getList(limit, page), getListRequest, getListSuccess, getListFailure);
};

export default {
  getList,
};
