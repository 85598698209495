import axios from 'axios';
import { AsyncStorage } from 'react-native';
import ENV from '../../utils/environment';
import STORAGE from '../../constants/storage';

const base = () => {
  const instance = axios.create({
    baseURL: ENV.API_URL,
    timeout: 5000,
    headers: {
      'content-type': 'application/json',
    },
  });
  instance.interceptors.request.use(async (config) => {
    const token = await AsyncStorage.getItem(STORAGE.token);
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  return instance;
};

export default base;
