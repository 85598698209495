import Base from './base';

export default {
  getByProductId: async (productId: number, customerId: string): Promise<any> => (await Base()).post(`/products/${productId}/inquiry`, {
    customerId,
  }),
  getByProductItemId: async (productId: number, productItemId: number, destination: string): Promise<any> => (await Base()).post(`/products/${productId}/items/${productItemId}/inquiry`, {
    destination,
  }),
  getByProductTypeId: async (productTypeId: number, destination: string): Promise<any> => (await Base()).post(`/product-types/${productTypeId}/product-items`, {
    destination,
  }),
};
