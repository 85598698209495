import React from 'react';
import { DefaultTheme, NavigationContainer, useLinking } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import AsyncStorage from '@react-native-community/async-storage';
import { connect } from 'react-redux';
import COLORS from '../constants/color';
import HomeNavigator from './home';
import * as Navigation from '../utils/navigation';
import STORAGE from '../constants/storage';
import Actions from '../reduxers/actions';

const navigatorTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: COLORS.blackLight,
  },
};

const prefix = Linking.makeUrl('/');
const config = {
  initialRouteName: 'home',
  screens: {
    home: 'home',
    payment: 'payment/:productId',
    auth: 'auth',
    profile: 'profile',
    point: 'point',
    'order-list': 'order/list',
    order: 'order/:receiptId',
    faq: 'faq',
    'edit-profile': 'profile/edit',
    'add-user-destination': 'destination/add',
  },
};

interface MainProps {
  current: () => void;
}

const AppNavigator = (props: MainProps): React.ReactElement => {
  const routeNameRef = React.useRef();
  const { getInitialState } = useLinking(Navigation.navigationRef, {
    prefixes: [prefix],
    config,
  });

  const [isReady, setIsReady] = React.useState(false);
  const [initialState, setInitialState] = React.useState();

  React.useEffect(() => {
    getInitialState().then((state: any) => {
      if (state !== undefined) {
        setInitialState(state);
      }
      setIsReady(true);
    });
  }, [getInitialState]);

  if (!isReady) {
    return <></>;
  }

  const initScreen = () => {
    AsyncStorage.getItem(STORAGE.token).then(async (token) => {
      if (token) {
        await props.current();
        if (Navigation.current() === 'auth') Navigation.navigate('profile');
        else Navigation.init(true);
      } else {
        Navigation.init(false);
      }
    });
  };

  return (
    <NavigationContainer
      theme={navigatorTheme}
      initialState={initialState}
      ref={Navigation.navigationRef}
      onReady={() => {
        initScreen();
      }}
      onStateChange={() => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = Navigation.current();

        if (previousRouteName !== currentRouteName) {
          initScreen();
        }
      }}
    >
      <HomeNavigator />
    </NavigationContainer>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => ({
  current: () => dispatch(Actions.user.current()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigator);
