import { StyleSheet } from 'react-native';
import { SCREEN_MAX_WIDTH } from '../../constants';

const Styles = StyleSheet.create({
  modalBackdrop: {
    maxWidth: SCREEN_MAX_WIDTH,
    marginHorizontal: 'auto',
  },
  icon: {
    marginRight: 5,
  },
});

export default Styles;
