import { Reducer } from 'redux';
import IPaymentMethodState from '../../interfaces/states/payment-method';
import ActionTypes from '../../constants/action-types';
import BaseStateDefault from '../../constants/state';

const initialState: IPaymentMethodState = { ...BaseStateDefault };

const reducer: Reducer<any> = (state: IPaymentMethodState = initialState, action): IPaymentMethodState => {
  switch (action.type) {
    case ActionTypes.paymentMethod.PAYMENT_METHOD_GET_LIST_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined,
      };
    }
    case ActionTypes.paymentMethod.PAYMENT_METHOD_GET_LIST_FAILURE: {
      return {
        ...state,
        requesting: false,
        error: action.payload,
      };
    }
    case ActionTypes.paymentMethod.PAYMENT_METHOD_GET_LIST_SUCCESS: {
      return {
        ...state,
        requesting: false,
        list: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
