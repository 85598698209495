import * as React from 'react';
import { connect } from 'react-redux';
import HomeScreen from '../screens/home';
import PaymentScreen from '../screens/payment';
import OrderScreen from '../screens/order';
import OrderListScreen from '../screens/order-list';
import AuthScreen from '../screens/auth';
import ProfileScreen from '../screens/profile';
import EditProfileScreen from '../screens/edit-profile';
import AddUserDestinationScreen from '../screens/add-user-destination';
import PointScreen from '../screens/point';
import FAQScreen from '../screens/faq';
import StackNavigation from './stack';
import LogoHeader from '../components/header/with-logo';
import { ReduxState } from '../reduxers/reducers';
import IUser from '../interfaces/models/user';

const HomeStackNavigation = [
  {
    name: 'home',
    component: HomeScreen,
    headerOptions: LogoHeader,
  },
  {
    name: 'payment',
    component: PaymentScreen,
    headerOptions: LogoHeader,
  },
  {
    name: 'order-list',
    component: OrderListScreen,
    headerOptions: LogoHeader,
  },
  {
    name: 'order',
    component: OrderScreen,
    headerOptions: LogoHeader,
  },
  {
    name: 'auth',
    component: AuthScreen,
    headerOptions: LogoHeader,
    withoutRightButton: true,
  },
  {
    name: 'profile',
    component: ProfileScreen,
    headerOptions: LogoHeader,
  },
  {
    name: 'edit-profile',
    component: EditProfileScreen,
    headerOptions: LogoHeader,
  },
  {
    name: 'add-user-destination',
    component: AddUserDestinationScreen,
    headerOptions: LogoHeader,
  },
  {
    name: 'point',
    component: PointScreen,
    headerOptions: LogoHeader,
  },
  {
    name: 'faq',
    component: FAQScreen,
    headerOptions: LogoHeader,
  },
];

interface MainProps {
  user?: IUser;
  isLoggedIn: boolean;
}

const HomeNavigation = (props: MainProps) => {
  const { isLoggedIn, user } = props;
  return <StackNavigation navigations={HomeStackNavigation} user={user} isLoggedIn={isLoggedIn} />;
};

HomeNavigation.defaultProps = {
  user: undefined,
};

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  user: state.user.data,
  isLoggedIn: !!state.user?.data,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HomeNavigation);
