import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import IPaymentMethod, { IPaymentMethodRequest } from '../../interfaces/models/payment-method';
import { IBaseResponse } from '../../interfaces/responses/base';
import Api from '../apis';
import Base from './base';
import ActionTypes from '../../constants/action-types';

const getListRequest = () => action(ActionTypes.paymentMethod.PAYMENT_METHOD_GET_LIST_REQUEST);
const getListFailure = (error: string) => action(ActionTypes.paymentMethod.PAYMENT_METHOD_GET_LIST_FAILURE, error);
const getListSuccess = (res: IBaseResponse<Array<IPaymentMethod>>) => action(ActionTypes.paymentMethod.PAYMENT_METHOD_GET_LIST_SUCCESS, res.data);

const getList = (data?: IPaymentMethodRequest) => (dispatch: Dispatch<AnyAction>) => {
  Base.CallAction(dispatch, Api.paymentMethod.getList(data), getListRequest, getListSuccess, getListFailure);
};

export default {
  getList,
};
