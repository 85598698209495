// similar naming to Figma
const COLORS = {
  theme: '#051F58',
  price: '#FF5518',
  infoBlue: '#1D6FB9',
  blueBG: 'rgba(210, 240, 251, 0.5)',
  blackOne: '#080808',
  blackTwo: '#6A6A6A',
  blackThree: '#B4B4B4',
  blackFour: '#C4C4C4',
  blackLight: '#F2F2F2',
  divider: '#E6E6E6',
  white: '#FFFFFF',
  backdropBG: 'rgba(0,0,0,0.25)',
  grey: '#656565',
  green: '#1AA016',
};

export default COLORS;
