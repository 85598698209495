import { StyleSheet, Dimensions } from 'react-native';
import { PADDING, SCREEN_MAX_WIDTH } from '../../constants';
import COLORS from '../../constants/color';

const { width } = Dimensions.get('window');

const Styles = StyleSheet.create({
  productItem: {
    backgroundColor: '#F1F3F6',
    padding: PADDING,
    borderRadius: 3,
    margin: PADDING / 2,
    borderWidth: 2,
    borderColor: '#F1F3F6',
    position: 'relative',
  },
  productItemActive: {
    borderColor: COLORS.price,
  },
  productItemSelection: {
    position: 'absolute',
    top: -2,
    right: -2,
  },
  paymentMethod: {
    backgroundColor: COLORS.divider,
    padding: PADDING,
  },
  checkoutModal: {
    backgroundColor: COLORS.white,
    padding: PADDING,
    width: width < SCREEN_MAX_WIDTH ? width : SCREEN_MAX_WIDTH,
    bottom: 0,
    top: 'unset',
  },
  checkoutModalBackdrop: {
    maxWidth: SCREEN_MAX_WIDTH,
    marginHorizontal: 'auto',
    backgroundColor: COLORS.backdropBG,
  },
  detailBox: {
    borderRadius: 1,
    shadowColor: COLORS.blackOne,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.16,
    shadowRadius: 5,
  },
});

export default Styles;
