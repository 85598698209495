import { StyleSheet } from 'react-native';

const createMarginVariant = (space: number) => {
  const whitespace = 16 * space;
  const variant: { [k: string]: any } = {};
  const spacestring = space.toString().split('.').join('_');
  variant[`MT${spacestring}`] = { marginTop: whitespace };
  variant[`MR${spacestring}`] = { marginRight: whitespace };
  variant[`MB${spacestring}`] = { marginBottom: whitespace };
  variant[`ML${spacestring}`] = { marginLeft: whitespace };
  variant[`MH${spacestring}`] = { marginHorizontal: whitespace };
  variant[`MV${spacestring}`] = { marginVertical: whitespace };
  variant[`M${spacestring}`] = { margin: whitespace };

  return variant;
};

const createPaddingVariant = (space: number) => {
  const whitespace = 16 * space;
  const variant: { [k: string]: any } = {};
  const spacestring = space.toString().split('.').join('_');
  variant[`PT${spacestring}`] = { paddingTop: whitespace };
  variant[`PR${spacestring}`] = { paddingRight: whitespace };
  variant[`PB${spacestring}`] = { paddingBottom: whitespace };
  variant[`PL${spacestring}`] = { paddingLeft: whitespace };
  variant[`PH${spacestring}`] = { paddingHorizontal: whitespace };
  variant[`PV${spacestring}`] = { paddingVertical: whitespace };
  variant[`P${spacestring}`] = { padding: whitespace };

  return variant;
};

const VarSpace = StyleSheet.create({
  ...createMarginVariant(0),
  ...createMarginVariant(0.5),
  ...createMarginVariant(1),
  ...createMarginVariant(1.5),
  ...createMarginVariant(2),
  ...createMarginVariant(3),
  ...createMarginVariant(4),
  ...createPaddingVariant(0),
  ...createPaddingVariant(0.5),
  ...createPaddingVariant(1),
  ...createPaddingVariant(1.5),
  ...createPaddingVariant(2),
  ...createPaddingVariant(3),
  ...createPaddingVariant(4),
  ...createPaddingVariant(5),
});

export default VarSpace;
