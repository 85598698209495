import * as React from 'react';
import { View, ViewStyle } from 'react-native';
import CommonStyle from '../../styles';
import { Capitalize } from '../../utils';

interface IProps {
  children: React.ReactNode;
  justify?: 'start' | 'center' | 'end' | 'space';
  align?: 'start' | 'center' | 'end' | 'space';
  style?: ViewStyle | Array<ViewStyle>;
}
const Row = ({ children, justify, align, style }: IProps) => {
  const styles = Array.isArray(style) ? style : [style];
  return (
    <View
      style={[
        CommonStyle.Layout.row,
        CommonStyle.Layout.full,
        CommonStyle.Layout[`justify${Capitalize(justify)}`],
        CommonStyle.Layout[`align${Capitalize(align)}`],
        ...styles,
      ]}
    >
      {children}
    </View>
  );
};

Row.defaultProps = {
  justify: 'start',
  align: 'start',
  style: {},
};

export default Row;
